(function() {
    window.timelyEventHub.sub('report.view', function (eventData) {
        const { reportName, reportId, viewType } = eventData;

        window.timelyGA.track({
            category: 'report',
            action: 'view',
            label: reportName,
            value: reportId,
            additionalData: {
                viewType
            }
        });
    });

    window.timelyEventHub.sub('reports.customise_columns_edit', function (eventData) {
        const { columns, filters, reportType } = eventData;

        window.timelyGA.track({
            category: 'report',
            action: 'edit_columns',
            label: 'edit_columns',
            value: columns,
            additionalData: {
                filters,
                reportType
            }

        });
    });

    window.timelyEventHub.sub('reports.customise_columns_reset', function (eventData) {
        const { columns, filters, reportType } = eventData;

        window.timelyGA.track({
            category: 'report',
            action: 'reset_columns',
            label: 'reset_columns',
            value: columns,
            additionalData: {
                filters,
                reportType
            }
        });
    });

    window.timelyEventHub.sub('reports.filters_update', function (eventData) {
        const { filters, columns, reportType } = eventData;

        window.timelyGA.track({
            category: 'report',
            action: 'update_filters',
            label: 'update_filters',
            value: filters,
            additionalData: {
                columns,
                reportType
            }
        });
    });

    window.timelyEventHub.sub('reports.filters_reset', function (eventData) {
        const { filters, columns, reportType } = eventData;

        window.timelyGA.track({
            category: 'report',
            action: 'reset_filters',
            label: 'reset_filters',
            value: filters,
            additionalData: {
                columns,
                reportType
            }
        });
    });

    window.timelyEventHub.sub('reports.filters_empty', function (eventData) {
        const { columns, filters, reportType } = eventData;

        window.timelyGA.track({
            category: 'report',
            action: 'filters_empty',
            label: 'filters_empty',
            value: columns,
            additionalData: {
                filters,
                reportType
            }
        });
    });

    window.timelyEventHub.sub('reports.client_link_clicked', function (customerId) {

        window.timelyGA.track({
            category: 'report',
            action: 'client_link_clicked',
            label: 'client_link_clicked',
            value: customerId,
        });
    });

    window.timelyEventHub.sub('reports.download', function (eventData) {
        const { format, reportId } = eventData;

        window.timelyGA.track({
            category: 'report',
            action: 'download',
            label: format,
            value: reportId,
        });
    });

    // Track clicks on the new report button on the old reports view
    window.timelyEventHub.sub('reports.button_click_client_overview_report', function () {
        window.timelyGA.track({ category: 'report', action: 'button_click_client_overview_report' });
    });
})();
