(function () {
    
    var category = 'targets';
    
    var targetTypeLookup = {
        1: 'service sales',
        2: 'product sales',
        3: 'total sales',
        4: 'service hourly',
        5: 'product hourly',
        6: 'total hourly'
    };

    window.timelyEventHub.sub('targets.created_target', function (data) {
        var targetType = data.targetType === undefined ? 'Unknown' : targetTypeLookup[data.targetType];
        var duration = data.duration === 1 ? 'weekly' : 'monthly';

        window.timelyGA.track({ category: category, action: 'created target', label: targetType });
        window.timelyGA.track({ category: category, action: 'created target', label: duration });
    });

    window.timelyEventHub.sub('targets.edited_target', function (data) {
        var targetType = data.targetType === undefined ? 'Unknown' : targetTypeLookup[data.targetType];
        window.timelyGA.track({ category: category, action: 'edited target', label: targetType });
    });

    window.timelyEventHub.sub('targets.deleted_target', function (data) {
        var targetType = data.targetType === undefined ? 'Unknown' : targetTypeLookup[data.targetType];
        window.timelyGA.track({ category: category, action: 'deleted target', label: targetType });
    });
}());
