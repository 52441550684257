/*
 * Project: Twitter Bootstrap Hover Dropdown
 * Author: Cameron Spear
 * Contributors: Mattia Larentis
 *
 * Dependencies?: Twitter Bootstrap's Dropdown plugin
 *
 * A simple plugin to enable twitter bootstrap dropdowns to active on hover and provide a nice user experience.
 *
 * No license, do what you want. I'd love credit or a shoutout, though.
 *
 * http://cameronspear.com/blog/twitter-bootstrap-dropdown-on-hover-plugin/
 */
/* eslint no-unused-vars: 0 */
(function($, window, undefined) { // eslint-disable-line no-extra-semi no-shadow-restricted-names
    // outside the scope of the jQuery plugin to
    // keep track of all dropdowns
    var $allDropdowns = $();

    // if instantlyCloseOthers is true, then it will instantly
    // shut other nav items when a new one is hovered over
    $.fn.dropdownHover = function(options) {

        // the element we really care about
        // is the dropdown-toggle's parentplugins\jquery.transit.js
        $allDropdowns = $allDropdowns.add(this.closest('.btn-group'));

        return this.each(function() {
            var $this = $(this).closest('.btn-group'),
                defaults = {
                    delay: 300,
                    instantlyCloseOthers: false
                },
                data = {
                    delay: $(this).data('delay'),
                    instantlyCloseOthers: $(this).data('close-others')
                },
                options = $.extend(true, {}, defaults, options, data),
                timeout,
                enterTimeout;

            $this.hover(function() {

                if($(window).width() < 940) return;

                if(options.instantlyCloseOthers === true)
                    $allDropdowns.removeClass('open');

                window.clearTimeout(timeout);
                window.clearTimeout(enterTimeout);

                var element = $(this);

                enterTimeout = window.setTimeout(function() {
                    element.addClass('open');
                }, 300);


            }, function () {
                if($(window).width() < 940) return;
                window.clearTimeout(enterTimeout);
                timeout = window.setTimeout(function() {
                    $this.removeClass('open');
                }, options.delay);
            });
        });
    };

    $(document).ready(function() {
        $('[data-hover="dropdown"]').dropdownHover();
    });
})(jQuery, this);
