﻿
/*
* Notify plugin form plugin
* Use this plugin to alert the user to a current action
* Has success and errors states
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

(function ($) {

    var alertHtml = '<div class="pop-alert" id="timely-alert"><div class="alert alert-info"><a class="close">×</a> <span class="alert-body"></span></div></div>';
    var defaults = {
        message: '',
        state: 'success',
        displayTime: 1000, //in ms
        selfClose: true
    };

    $.widget("nl.notify", {
        options: {
        },

        _create: function () {

            var self = this;
            self.hasAlert = false;
        },

        note: function (opts) {

            var self = this;
            var options = $.extend(defaults, opts);
            self.close();
            self.alertOuter = $(alertHtml);
            self.hasAlert = true;

            $('span', self.alertOuter).append(options.message);

            self.alert = $('.alert', self.alertOuter);
            self.alert.addClass("alert-" + options.state);

            $(document.body).prepend(self.alertOuter);
            setTimeout(function () {
                self.alert.addClass('show');
            }, 20);


            var hideTimeout = setTimeout(function () {
                if (options.selfClose) {
                    self.alert.removeClass('show');
                    setTimeout(function () {
                        self.alertOuter.remove();
                    }, 300);
                }
            }, options.displayTime);


            $('.close', self.alertOuter).click(function (e) {
                e.preventDefault();
                self.alert.removeClass('show');
                setTimeout(function () {
                    self.alertOuter.remove();
                }, 300);

                clearTimeout(hideTimeout);
                self.hasAlert = false;
            });

        },

        close: function () {
            var self = this;
            if(self.hasAlert) {
                setTimeout(function () {
                    $('#timely-alert').remove();
                    self.hasAlert = false;
                }, 300);
            }
        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) { // eslint-disable-line no-unused-vars
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);
