﻿/*
* Chargify plugin
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

(function ($) {
    $.fn.subscriptionStatus = function (action) {

        //get the account status for each referred account
        this.each(
            function () {
                var statusDiv = $(this);
                var businessid = statusDiv.data("businessid");
                $.getJSON(action + businessid, null, function (data) {
                    var labelClass = ' label-default';
                    if (data.status == 'Active') {
                        labelClass = ' label-success';
                        $('.refer-redeem-' + businessid).show();
                    }
                    else if (data.status == 'Trialing') {
                        labelClass = ' label-info';
                    }
                    statusDiv.html('<span class="label' + labelClass + '">' + data.status + '</span>');
                });
            }
        );

    };
})(jQuery);