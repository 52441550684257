(function () {

    var category = 'staff_access';

    window.timelyEventHub.sub('staff_access.save_selected_location', function () {
        window.timelyGA.track({ category: category, action: 'save_selected_location', label: 'staff_access' });
    });

    window.timelyEventHub.sub('staff_access.selected_location', function () {
        window.timelyGA.track({ category: category, action: 'selected_location', label: 'staff_access' });
    });

    window.timelyEventHub.sub('staff_access.activity_log_search', function () {
        window.timelyGA.track({ category: category, action: 'activity_log_search', label: 'activity_log' });
    });

    window.timelyEventHub.sub('staff_access.activity_log_click_item_link', function (data) {
        window.timelyGA.track({ category: category, action: 'activity_log_click_item_link', label: data.area });
    });

    window.timelyEventHub.sub('staff_access.activity_log_click_show_more', function () {
        window.timelyGA.track({ category: category, action: 'activity_log_click_show_more', label: 'activity_log' });
    });

    window.timelyEventHub.sub('staff_access.access_list_click_reset_password', function () {
        window.timelyGA.track({ category: category, action: 'access_list_click_reset_password', label: 'staff_access_list' });
    });

    window.timelyEventHub.sub('staff_access.access_list_click_view_activity', function () {
        window.timelyGA.track({ category: category, action: 'access_list_click_view_activity', label: 'staff_access_list' });
    });

}());