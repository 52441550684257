(function () {

    var category = 'social_media';

    window.timelyEventHub.sub('fbe.fb_connected', function () {
        window.timelyGA.track({ category, action: 'integration_setup_fb', label: 'fbe_facebook_connected' });
    });

    window.timelyEventHub.sub('fbe.ig_connected', function () {
        window.timelyGA.track({ category, action: 'integration_setup_ig', label: 'fbe_instagram_connected' });
    });

    window.timelyEventHub.sub('fbe.uninstalled', function () {
        window.timelyGA.track({ category, action: 'integration_uninstalled', label: 'fbe_uninstalled' });
    });
}());
