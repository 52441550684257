(function () {

    var category = 'services';
    var label = 'services';


    window.timelyEventHub.sub('service.added', function () {
        window.timelyGA.track({ category: category, action: 'service_added', label: label });
    });


    window.timelyEventHub.sub('service.saved', function () {
        window.timelyGA.track({ category: category, action: 'service_saved', label: label });
    });

}());
