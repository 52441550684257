(function () {

    var category = 'calendar';
   
    window.timelyEventHub.sub('calendar.mark_staff_requested', function () {
        window.timelyGA.track({ category: category, action: 'mark_staff_requested', label: 'order' });
    });

    window.timelyEventHub.sub('calendar.unmark_staff_requested', function () {
        window.timelyGA.track({ category: category, action: 'unmark_staff_requested', label: 'order' });
    });

    window.timelyEventHub.sub('calendar.refresh', function () {
        window.timelyGA.track({ category: category, action: 'refresh', label: 'order' });
    });

    window.timelyEventHub.sub('calendar.edit_roster', function () {
        window.timelyGA.track({ category: category, action: 'edit_roster_click', label: 'order' });
    });

    window.timelyEventHub.sub('calendar.day_view_from_col_header', function () {
        window.timelyGA.track({ category: category, action: 'day_view_from_col_header', label: 'order' });
    });

    window.timelyEventHub.sub('calendar.week_view_from_col_header', function () {
        window.timelyGA.track({ category: category, action: 'week_view_from_col_header', label: 'order' });
    });

    window.timelyEventHub.sub('calendar.day_sheet', function () {
        window.timelyGA.track({ category: category, action: 'day_sheet', label: 'order' });
    });

    window.timelyEventHub.sub('calendar.blur_customers', function () {
        window.timelyGA.track({ category: category, action: 'blur_customers', label: 'order' });
    });

    window.timelyEventHub.sub('calendar.add_item', function (data) {
        window.timelyGA.track({ category: category, action: 'add_item', label: data.type });
    });

}());