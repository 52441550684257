(function () {

    var category = 'activated alert';
    var label = 'activated success';

    window.timelyEventHub.sub('activated_alert.activated', function () {
        window.timelyGA.track({ category: category, action: 'activated', label: label });
    });

    window.timelyEventHub.sub('activated_alert.help', function () {
        window.timelyGA.track({ category: category, action: 'click get help', label: label });
    });

    window.timelyEventHub.sub('activated_alert.import', function () {
        window.timelyGA.track({ category: category, action: 'click view import data', label: label });
    });

    window.timelyEventHub.sub('activated_alert.dashboard', function () {
        window.timelyGA.track({ category: category, action: 'click dashboard', label: label });
    });

    window.timelyEventHub.sub('activated_alert.increase_retention', function () {
        window.timelyGA.track({ category: category, action: 'click increase retention', label: label });
    });

}());