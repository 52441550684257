/*
* Chargify plugin
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

(function ($) {
    $.fn.numericOnly = function () {

        this.keypress(function (e) {

            //allow backspace,tab,enter,left,right,delete
            if (/^(8|9|13|37|39|46)$/i.test(e.which || e.keyCode)) { return true; }

            var charCode = e.which || e.keyCode;
            var charStr = String.fromCharCode(charCode);

            var negativeAllowed = $(this).data('negative');

            if (typeof negativeAllowed != 'undefined' && negativeAllowed === 'True') {
                return /[-.0-9_]/i.test(charStr);
            }
            return /[.0-9_]/i.test(charStr);
        });
    };

    $.fn.digitalOnly = function () {

        this.keypress(function (e) {

            //allow backspace,tab,enter
            var validKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', 'Enter'];
            if (validKeys.includes(e.key)) {
                return true;
            }

            var charCode = e.which || e.keyCode;
            var charStr = String.fromCharCode(charCode);
            return /[0-9]/i.test(charStr);
        });
    };

    $.fn.timespanOnly = function () {

        this.keypress(function (e) {

            //allow backspace,tab,enter,left,right,delete
            if (/^(8|9|13|37|39|46)$/i.test(e.which || e.keyCode)) { return true; }

            var charCode = e.which || e.keyCode;
            var charStr = String.fromCharCode(charCode);

            return /[:0-9]/i.test(charStr);
        });
    };

    $.fn.alphaNumericOnly = function () {
        var self = this;
        this.keypress(function (e) {
            //allow backspace,tab,enter,left,right,delete
            if (/^(8|9|13|37|39|46)$/i.test(e.which || e.keyCode)) { return true; }

            var charCode = e.which || e.keyCode;
            var charStr = String.fromCharCode(charCode);

            return /[a-z0-9_]/i.test(charStr);
        });
        $(this).on('paste', function (e) { // eslint-disable-line no-unused-vars
            setTimeout(function () {
                var text = $(self).val().replace(/[\W_]+/g,"");
                $(self).val(text);
                // do something with text
            }, 1);
            return true;
        });
    };

    $.fn.formatNumber = function(decimalPlaces) {
        var amount = Number($(this).val());
        if (isNaN(amount)) amount = 0;
        return $(this).val(amount.toFixed(decimalPlaces == null ? 2 : decimalPlaces));
    };

    $.fn.stripInvalidChars = function(target) {

        this.keyup(function (e) { // eslint-disable-line no-unused-vars

            var stt = $(this).val();
            target.val(stt.replace(/[^a-z0-9]/gi, '').toLowerCase());
        });
    };

    $.fn.selectAllText = function () {
        this.click(function () {
            this.focus();
            if (this.selectionStart == this.selectionEnd)
            {
                this.select();
            }
        });
    };

})(jQuery);
