(function () {

    var category = 'promo_codes';
   
    window.timelyEventHub.sub('promo_codes.add', function () {
        window.timelyGA.track({ category: category, action: 'add_code', label: 'add code' });
    });

    window.timelyEventHub.sub('promo_codes.edit', function () {
        window.timelyGA.track({ category: category, action: 'edit_code', label: 'edit code' });
    });

    window.timelyEventHub.sub('promo_codes.try-it', function () {
        window.timelyGA.track({ category: category, action: 'add_code_via_banner', label: 'add code' });
    });

    window.timelyEventHub.sub('promo_codes.reorder', function () {
        window.timelyGA.track({ category: category, action: 'edit_code', label: 'edit code' });
    });

    window.timelyEventHub.sub('promo_codes.apply', function (location) {
        window.timelyGA.track({ category: category, action: 'apply', label: location });
    });

    window.timelyEventHub.sub('promo_codes.remove', function (location) {
        window.timelyGA.track({ category: category, action: 'remove', label: location });
    });

    window.timelyEventHub.sub('promo_codes.show_tips', function () {
        window.timelyGA.track({ category: category, action: 'show_tips', label: location });
    });
    

}());
