/* global StatusPage */
(function ($) {
    var sp = new StatusPage.page({page: '6gvv3w51gh7r'});

    var statusMap = {
        'none': 'success',
        'minor': 'warning',
        'major': 'error',
        'critical': 'error'
    };

    $('.help-btn-group').on('click', function () {
        var el = $(this).find('.timely-status');
        var link = el.closest('a');
        var icon = el.find('.timely-status__icon');

        var isVisible = !($(this).hasClass('open'));
        var isLoading = el.attr('data-is-loading') == 'true';

        icon.addClass('fa fa-spin fa-spinner');
        link.attr('title', null);
        el.attr('class', 'timely-status pull-right');

        if (isVisible && !isLoading) {
            el.attr('data-is-loading', true);

            sp.status({
                success: function (data) {
                    var timelyStatus = statusMap[data.status.indicator];

                    link.attr('title', data.status.description)
                    el.addClass('timely-status--' + timelyStatus).attr('data-is-loading', false);
                    icon.removeClass('fa-spin fa-spinner')
                }
            });
        }

    });
}(window.jQuery));