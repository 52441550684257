﻿// Adds Cookies object to the global scope which provides a wrapper to make
// dealing with the document.cookies object nicer.
//
// Supported methods are:
// Get - e.g window.Cookies.get("some_cookie_name")
// Set - e.g window.Cookies.set("some_cookie_name", "a cookie value")
// Names - e.g. window.Cookies.names() - returns an array of all the cookie keys
// Clear - e.g. window.Cookies.clear("some_cookie_name") - if you pass in no name it will clear all the cookies
// HasCookies - e.g. window.Cookies.hasCookies() - will return true if the browser supports cookies

/* eslint no-unused-vars: 0 */
_.extend(window.Cookies = window.Cookies || {}, (function (document) {
    var cookies, oldCookieStr;

    var _trim = function (str) { return str.replace(/^\s+|\s+$/g, ''); };

    var _hasCookies = (function () {
        var ret;
        if (window.navigator && navigator.cookieEnabled) {
            return true;
        }
        document.cookie = 'testingcookies=1';
        ret = document.cookie.indexOf('testingcookies=') != -1;
        document.cookie = 'testingcookies=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
        return ret;
    })();

    var _check = function (checkFunc) {
        return function () {
            if (!_hasCookies) {
                return null;
            } else {
                return checkFunc.apply(window.Cookies, arguments);
            }
        };
    };

    var _get = function (name) {
        var pairs, c, key, value;
        if (oldCookieStr !== document.cookie) {
            oldCookieStr = document.cookie;
            if (document.cookie.length > 0) {
                pairs = document.cookie.split('; ');
                for (c = 0; c < pairs.length; c += 1) {
                    var pair = pairs[c];
                    var firstEq = pair.indexOf('=');
                    key = pair.substr(0, firstEq);
                    value = pair.substr(firstEq + 1);
                    this[key] = value;
                }
            }
        }
        if (name) {
            if (!_.isEmpty(this[name])) {
                return decodeURIComponent(this[name]);
            }
        } else {
            return this;
        }
    };


    var _names = function () {
        var names = [];
        for (key in this) { // eslint-disable-line no-undef
            if ({}.hasOwnProperty.call(this, key)) { // eslint-disable-line no-undef
                if (['get', 'set', 'names', 'clear', 'hasCookies'].indexOf(key) === -1) { // eslint-disable-line no-undef
                    names.push(key); // eslint-disable-line no-undef
                }
            }
        }
        return names;
    };

    var _set = function (name, value, expires) {
        var cookieStr;
        if (name &&
            value &&
            _.isString(name) &&
            (_.isString(value) || _.isNumber(value) || _.isDate(value))) {

            value = _trim('' + value); // need to be a string
            value = (!_.isEmpty('' + value)) ? encodeURIComponent(value) : '';
            cookieStr = _trim(name) + '=' + value + '; ';
            if (expires) {
                 if (_.isObject(expires)) {
                    throw Error('invalid expires object given for cookie' + name);
                }
                expires = moment().add(expires.units, expires.number).format('ddd, MMM YYYY H:mm:ss');
                cookieStr += 'expires=' + expires + ';';
            }
            var path = 'path=/';
            cookieStr += path; // mandatory to set it to avoid duplicates
            document.cookie = _trim(cookieStr);
        } else {
            throw Error('Failed to set cookie.');
        }
        return this.get();
    };

    var _clear = function (name) {
        var n;
        this.get();
        if (name) {
            document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
            delete this[name];
            return this.get();
        } else {
            var names = _names.call(this);
            for (n = 0; n < names.length; n += 1) {
                _clear.call(this, names[n]);
            }
            return this.get();
        }
    };

    cookies = {
        get: _check(_get),
        names: _check(_names),
        set: _check(_set),
        clear: _check(_clear),
        hasCookies: _hasCookies
    };

    cookies.get();

    return cookies;
})(window.document || {}));