﻿
/*
* Timely Typeahead
*
* Depends:
*   jquery.ui.widget.js
*/

/* eslint no-unused-vars: 0 */
(function ($) {

    "use strict"; // jshint ;_;

    $.widget(".timelyTypeahead", {
        options: {
            updater: function (item) { return item; },
            matcher: function (item) {
                return true;
            },
            sorter: function (items) {
                return items;
            },
            highlighter: function (item) {
                var self = this;
                // eslint-disable-next-line no-useless-escape
                var query = self.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
                return (item || '').replace(new RegExp('(' + query + ')', 'ig'), function ($1, match) {
                    return '<strong>' + match + '</strong>';
                });
            },
            onselect: function(item) {
                return false;
            },
            source: [],
            ajaxSource: false,
            items: 8,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>',
            minLength: 1,
            firstProperty: "FullName",
            secondProperty: "BestContact",
            leaveInput: false
        },

        _create: function () {

            var self = this,
            options = self.options;
            self.theInput = $(self.element);

            var ua = navigator.userAgent;
            self.isIpad = ua.indexOf("iPad") >= 0;

            self.updater = options.updater;
            self.matcher = options.matcher;

            self.sorter = options.sorter;

            self.highlighter = options.highlighter;
            self.onselect = options.onselect;
            self.xhr = false;
            self.source = options.source;
            self.ajaxSource = options.ajaxSource;
            self.menu = $(options.menu);
            self.isShowing = false;
            self.listen();

        },

        canShow: function() {
            var self = this;
            //Check to make sure the original text input is still visible on the page before showing
            return self.theInput.width() > 0;

        },

        select: function() {
            var self = this;

            //grab the item from the menu that the user has selected
            var selectedVal = JSON.parse(self.menu.find('.active').attr('data-value'));
            var selectedText = selectedVal[self.options.firstProperty];

            //run the value through the updater function then stick it in the input
            if (!self.options.leaveInput) {
                self.theInput.val(self.updater(selectedText)).change();
            } else {
                self.theInput.val('');
            }

            self.onselect(selectedVal, self.theInput);

            return self.hide();
        },

        show: function () {
            var self = this;
            if (!self.canShow()) return self;

            var position = $.extend({}, self.theInput.offset(), {
                height: self.theInput[0].offsetHeight
            });

            $(document.body).append(self.menu);
            var menuHeight = self.menu.height();
            var maxHeight = position.top - 12;

            if (self.isIpad) {
                self.menu.addClass('dropdown--no-point');
                self.menu.css({
                    top: Math.max(0, maxHeight - menuHeight),
                    left: position.left,
                    maxHeight: maxHeight
                }).show();
            } else {
                self.menu.removeClass('dropdown--no-point');
                self.menu.css({
                    top: position.top + position.height,
                    left: position.left
                }).show();
            }



            self.isShowing = true;
            return self;
        },

        hide: function () {
            var self = this;
            self.menu.hide();
            self.isShowing = false;
            return self;
        },

        lookup: function (event) {
            var self = this;
            var items;

            self.query = self.theInput.val();

            //if the passed in value is nothing, dont do anything
            if (!self.query || self.query.length < self.options.minLength) {
                return self.isShowing ? self.hide() : self;
            }

            //If an ajaxSource has been passed in rather than a normal source function/array use it instead of the source field
            if (self.ajaxSource) {

                if (self.xhr) self.xhr.abort();
                var data = { };
                data[self.ajaxSource.paramName] = self.query;
                if (self.ajaxSource.additionalData) $.extend(data, self.ajaxSource.additionalData);
                self.xhr = $.post(self.ajaxSource.url, data, function (returnedData) {
                    self.xhr = false;
                    return self.process(returnedData);
                });

            }else {
                //If source is a function - call it passing in the query and the process function as a call back
                //If it isn't a function then assume it is just a list of items;
                items = $.isFunction(self.source) ? self.source(self.query, $.proxy(self.process, self)) : self.source;
            }

            return items ? self.process(items) : self;
        },

        process: function (items) {
            var self = this;

            //filter the list of items based on the matcher function
            items = $.grep(items, function(item) {
                return self.matcher(item);
            });

            //sort the items based on the sorter function
            items = self.sorter(items);

            //No items left? then hide the menu
            if (!items.length) {
                return self.isShowing ? self.hide() : self;
            }

            return self.focused ? self.render(items.slice(0, self.options.items)).show() : self;
        },

        render: function (items) {
            var self = this;

            items = $(items).map(function (i, item) {

                var ele = $(self.options.item).attr('data-value', JSON.stringify(item));
                var displayVal = item[self.options.firstProperty];
                var secondValue = "";

                if (self.options.secondProperty) {
                    secondValue = " <span>" + item[self.options.secondProperty] + "</span>";
                }

                ele.find('a').html(self.highlighter(displayVal) + secondValue);
                return ele[0];
            });

            items.first().addClass('active');

            self.menu.html(items);
            return self;
        },

        next: function (event) {
            var self = this;
            var active = self.menu.find('.active').removeClass('active');
            var next = active.next();
            if (!next.length) {
                next = $(self.menu.find('li')[0]);
            }

            if (self.isIpad) {
                var menuHeight = this.menu.height();
                var activeItemPosition = next.position().top + next.height();
                var newMenuScroll = (activeItemPosition > menuHeight) ? activeItemPosition - menuHeight + this.menu.scrollTop() : 0;

                this.menu.scrollTop(newMenuScroll)
            }

            next.addClass('active');
        },

        prev: function (event) {
            var self = this;
            var active = self.menu.find('.active').removeClass('active');
            var prev = active.prev();
            if (!prev.length) {
                prev = self.menu.find('li').last();
            }

            if (self.isIpad) {
                var activeItemPosition = prev.position().top;
                var maxScroll = this.menu[0].scrollHeight - this.menu.height();
                var newMenuScroll = (activeItemPosition < 0) ? activeItemPosition + this.menu.scrollTop() : maxScroll;

                this.menu.scrollTop(newMenuScroll)
            }

            prev.addClass('active');
        },

        listen: function () {
            var self = this;
            self.theInput
                .on('focus', $.proxy(self.focus, self))
                .on('blur', $.proxy(self.blur, self))
                .on('keypress', $.proxy(self.keypress, self))
                .on('keyup', $.proxy(self.keyup, self));

            if (self.eventSupported('keydown')) {
                self.theInput.on('keydown', $.proxy(self.keydown, self));
            }
            self.menu
                .on('click', $.proxy(self.click, self))
                .on('mouseenter', 'li', $.proxy(self.mouseenter, self))
                .on('mouseleave', 'li', $.proxy(self.mouseleave, self));
        },

        eventSupported: function (eventName) {
            var self = this;
            var isSupported = eventName in self.theInput;
            if (!isSupported) {
                self.theInput.setAttribute(eventName, 'return;');
                isSupported = typeof self.theInput[eventName] === 'function';
            }
            return isSupported;
        },

        move: function (e) {
            var self = this;
            if (!self.isShowing) return;

            switch (e.keyCode) {
                case 9: // tab
                case 13: // enter
                case 27: // escape
                    e.preventDefault();
                    break;
                case 38: // up arrow
                    e.preventDefault();
                    self.prev();
                    break;
                case 40: // down arrow
                    e.preventDefault();
                    self.next();
                    break;
            }
            e.stopPropagation();
        },

        keydown: function (e) {
            var self = this;
            self.suppressKeyPressRepeat = ~$.inArray(e.keyCode, [40, 38, 9, 13, 27]);
            self.move(e);
        },

        keypress: function (e) {
            var self = this;
            if (self.suppressKeyPressRepeat) return;
            this.move(e);
        },

        keyup: function (e) {
            var self = this;

            switch (e.keyCode) {
                case 40: // down arrow
                case 38: // up arrow
                case 16: // shift
                case 17: // ctrl
                case 18: // alt
                    break;
                case 13: // enter
                    if (self.xhr) self.xhr.abort();
                    if (!self.isShowing) return;
                    this.select();
                    break;
                case 9: // tab
                case 27: // escape
                    if (self.xhr) self.xhr.abort();
                    if (!self.isShowing) return;
                    self.hide();
                    break;
                default:
                    clearTimeout(self.lookupTimeout);
                    self.lookupTimeout = setTimeout(function () {
                        self.lookup();
                    }, 200);
            }

            e.stopPropagation();
            e.preventDefault();
        },

        focus: function (e) {
            var self = this;
            self.focused = true;
        },

        blur: function (e) {
            var self = this;
            self.focused = false;
            if (!self.mousedover && self.isShowing) self.hide();
        },

        click: function (e) {
            var self = this;
            e.stopPropagation();
            e.preventDefault();
            self.select();
            self.theInput.focus();
        },

        mouseenter: function (e) {
            var self = this;
            self.mousedover = true;
            self.menu.find('.active').removeClass('active');
            $(e.currentTarget).addClass('active');
        },

        mouseleave: function (e) {
            var self = this;
            self.mousedover = false;
            if (!self.focused && self.isShowing) self.hide();
        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);
