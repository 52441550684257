
(function () {
    // Simplified/stripped down version of underscore's throttle (Source https://stackoverflow.com/a/27078401)
    function throttle(callback, limit) {
        var waiting = false;                      // Initially, we're not waiting
        return function () {                      // We return a throttled function
            if (!waiting) {                       // If we're not waiting
                callback.apply(this, arguments);  // Execute users function
                waiting = true;                   // Prevent future invocations
                setTimeout(function () {          // After a period of time
                    waiting = false;              // And allow future invocations
                }, limit);
            }
        }
    }

    var throttleLimitMillis = 1000; // Allow 1 log per second

    var LOG_TYPES = {
        'ERROR': 0,
        'WARNING': 1,
        'INFORMATIONAL': 2
    };

    var log = throttle(doSendLog, throttleLimitMillis);

    window.timelyEventHub.sub('log.error', function (data) {
        log(LOG_TYPES.ERROR, data.message, data.customData);
    });

    window.timelyEventHub.sub('log.warning', function (data) {
        log(LOG_TYPES.WARNING, data.message, data.customData);
    });

    window.timelyEventHub.sub('log.informational', function (data) {
        log(LOG_TYPES.INFORMATIONAL, data.message, data.customData);
    });

    function doSendLog(logType, message, customData) {
        var endpoints = {
            [LOG_TYPES.ERROR]: 'LogError',
            [LOG_TYPES.WARNING]: 'LogWarning',
            [LOG_TYPES.INFORMATIONAL]: 'LogInformational'
        };

        return $.ajax({
            url: '/Logging/' + endpoints[logType],
            method: 'POST',
            contentType: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                message: message,
                customData: customData
            })
        });
    }

    
}());
