﻿/*
* ajaxLink plugin
* will send an ajax request on a link click
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

/* eslint no-unused-vars: 0 */
(function ($) {

    $.widget("nl.ajaxLinker", {
        options: {
            callback: function() {
            }
        },

        _create: function () {
            var self = this,
            options = self.options;

            $(document).on("click", "a.ajaxer", function (e) {

                e.preventDefault();
                var link = $(e.currentTarget);
                //first check if there is a data-href
                var url = link.data('href');
                if (!url || url.length == 0) url = link.attr('href');

                var refreshCal = link.data().refreshCal === 'yes';

                $(document).notify('note', { message: "Loading...", state: 'info', selfClose: false });

                $.ajax({
                    type: 'POST',
                    url: url,
                    success: function (data, textStatus, XMLHttpRequest) {
                        $(document).notify('close');
                        if (refreshCal) {
                            if ($('#calendar').length > 0) {
                                $('#calendar').fullCalendar('refetchEvents');
                            }
                        }
                    },
                    dataType: 'html'
                });

            });
        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);