(function () {

    var category = 'merchant_form_terminal';

    window.timelyEventHub.sub('merchant_form_terminal.view_new_card', function () {
        window.timelyGA.track({ category: category, action: 'merchant_form_terminal_view_new_card', label: 'view_new_card' });
    });

    window.timelyEventHub.sub('merchant_form_terminal.view_card_list', function () {
        window.timelyGA.track({ category: category, action: 'merchant_form_terminal_view_card_list', label: 'view_card_list' });
    });

    window.timelyEventHub.sub('merchant_form_terminal.save_new_card_success', function () {
        window.timelyGA.track({ category: category, action: 'merchant_form_terminal_save_new_card_success', label: 'save_new_card_success' });
    });

    window.timelyEventHub.sub('merchant_form_terminal.save_new_card_error', function (data) {
        window.timelyGA.track({ category: category, action: 'merchant_form_terminal_save_new_card_error', label: data.label });
    });

    var category2 = 'merchant_form_terminal_v2';

    window.timelyEventHub.sub('merchant_form_terminal_v2.save_new_card_success', function () {
        window.timelyGA.track({ category: category2, action: 'merchant_form_terminal_save_new_card_success', label: 'save_new_card_success' });
    });

    window.timelyEventHub.sub('merchant_form_terminal_v2.save_new_card_error', function (data) {
        window.timelyGA.track({ category: category2, action: 'merchant_form_terminal_save_new_card_error', label: data.label });
    });

}());
