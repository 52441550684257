/* ============================================================
 * bootstrap-dropdown.js v2.0.2
 * http://twitter.github.com/bootstrap/javascript.html#dropdowns
 * ============================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ============================================================ */


/* eslint no-unused-vars: 0 */
!function( $ ){

    "use strict";

 /* DROPDOWN CLASS DEFINITION
  * ========================= */

    var toggle = '[data-toggle="dropdown"]', Dropdown = function(element) {
        var $el = $(element).on('click.dropdown.data-api', this.toggle);
        $('html').on('click.dropdown.data-api', function() {
            $el.closest('.btn-group, .fc-jump').removeClass('open');
        });
    };

    Dropdown.prototype = {
        constructor: Dropdown,
        toggle: function(e) {
            var $this = $(this), selector = $this.attr('data-target'), $parent, isActive, isFixed = !!$this.attr('data-fixed');

            if (!selector) {
                selector = $this.attr('href');
                selector = selector && selector.replace( /.*(?=#[^\s]*$)/ , ''); //strip for ie7
            }

            try {
                $parent = $(selector);
            } catch (e) {
                // Updating jQuery to v3.x made passing '#' as a selector throw an error
                // Do nothing if there's an error
            }
            ($parent && $parent.length) || ($parent = $this.closest('.btn-group, .fc-jump'));

            var $menu = $('.dropdown-menu', $parent);
            isActive = $parent.hasClass('open');
            if (isFixed) {
                $menu.removeClass('dropdown-fixed');
                $menu.css({ left: '', top: '' })
            }

            clearMenus();
            if (!isActive) {
                $parent.toggleClass('open');
                var windowWidth = $(window).width();
                var dropPos = getPosition($menu);
                if (isFixed) {
                    $menu.addClass('dropdown-fixed');
                    $menu.css({ left: dropPos.left + 'px', top: dropPos.top - $(document).scrollTop() + 'px' })
                } else {
                    $parent.removeClass('drop-left');
                    //Check if dropdown is off the screen
                    if ((dropPos.left + dropPos.width) > windowWidth) {
                        $parent.addClass('drop-left');
                    } else {
                        $parent.removeClass('drop-left');
                    }
                }
            }

            return false;
        }
    };

    function getPosition (element) {
        return $.extend({}, (element.offset()), {
            width: element[0].offsetWidth,
            height: element[0].offsetHeight
        });
    }

    function clearMenus() {
      $(toggle).parent().removeClass('open');
  }


  /* DROPDOWN PLUGIN DEFINITION
   * ========================== */

    $.fn.dropdown = function(option) {
        return this.each(function () {
            var $this = $(this), data = $this.data('dropdown');
            if (!data) $this.data('dropdown', (data = new Dropdown(this)));
            if (typeof option == 'string') data[option].call($this);
        });
    };

    $.fn.dropdown.Constructor = Dropdown;


  /* APPLY TO STANDARD DROPDOWN ELEMENTS
   * =================================== */

    $(function() {
        $('html').on('click.dropdown.data-api', clearMenus);
        $('body').on('click.dropdown.data-api', toggle, Dropdown.prototype.toggle);
    });

}( window.jQuery );
