(function () {

    var category = 'upsell';

    window.timelyEventHub.sub('upsell.click_upgrade', function (el) {
        var banner = '';
        if (typeof el === 'string') {
            banner = el;
        } else {
            banner = $(el).data('banner');
        }

        banner = banner.toLowerCase().replace(/\W/g, '_');
        window.timelyGA.track({ category: category, action: 'click_upgrade', label: banner });
    });

}());
