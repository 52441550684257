(function () {

    var category = 'rebooking';
   
    window.timelyEventHub.sub('rebooking.book_next', function () {
        window.timelyGA.track({ category: category, action: 'book next', label: 'book next' });
    });

    window.timelyEventHub.sub('rebooking.book_next_jump', function () {
        window.timelyGA.track({ category: category, action: 'book next jump', label: 'book next' });
    });

    window.timelyEventHub.sub('rebooking.book_another', function () {
        window.timelyGA.track({ category: category, action: 'book another appointment', label: 'book another appointment' });
    });

    window.timelyEventHub.sub('sale_complete.book_next_page_old', function () {
        window.timelyGA.track({ category: category, action: 'book next page old', label: 'book next' });
    });

    window.timelyEventHub.sub('sale_complete.book_next_page_new', function () {
        window.timelyGA.track({ category: category, action: 'book next page new', label: 'book next' });
    });

    window.timelyEventHub.sub('sale_complete.book_next_button_old', function () {
        window.timelyGA.track({ category: category, action: 'book next button old', label: 'book next' });
    });

    window.timelyEventHub.sub('sale_complete.book_next_button_new', function () {
        window.timelyGA.track({ category: category, action: 'book next button new', label: 'book next' });
    });

}());
