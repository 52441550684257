﻿
/*
* Change tracker
* Detects and keeps a count of all the changed that have happened to the form
* elements that match the passed in selector. Allows for a function to be run
* everytime a change is detected
*
* Usage:
* $('.container-of-elements-to-be-tracked').changeTracker(options)
*
* Options:
* selector = the selector string that determines what elements should be monitored for changes
* eventsToWatch = what events represent a change that should be tracked
* onChange = a function to be run every time a change is detected
*
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

/* eslint no-unused-vars: 0 */
(function ($) {



    $.widget("timely.changeTracker", {
        options: {
            selector: 'input, textarea, select',
            eventsToWatch: 'keydown change',
            onChange: function (changedElement) {
            }
        },

        _create: function () {

            var self = this,
            options = self.options;
            self.container = self.element;
            self.changes = 0;

            self.container.on(options.eventsToWatch, options.selector, function () {
                self.changes++;
                options.onChange(this);
            });

        },

        hasChanged: function () {
            var self = this;
            return self.changes > 0;
        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);
