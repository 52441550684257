﻿/* eslint no-unused-vars: 0 */
(function () {
    redirectingAlready = false; // eslint-disable-line no-undef
    var refresh = function (event, xhr, settings) {
        var loginStatus = xhr.responseText;
        if (loginStatus && loginStatus.indexOf("TimelyTwoFactorVerificationSite") != -1) {
            if (!redirectingAlready) { // eslint-disable-line no-undef
                redirectingAlready = true; // eslint-disable-line no-undef
                alert('Sorry your two factor auth has expired, please verify to continue.');
                window.location = '/TwoFactorAuthentication/Verify?ReturnUrl=' + encodeURIComponent(window.location.pathname);
            }
        }
        if (loginStatus && loginStatus.indexOf("TimelyLoggedOutSite") != -1) {
            if (!redirectingAlready) { // eslint-disable-line no-undef
                redirectingAlready = true; // eslint-disable-line no-undef
                alert('Sorry you are currently logged out, please login to continue.');
                window.location = '/Account/Login?ReturnUrl=' + encodeURIComponent(window.location.pathname);
            }
        }
    };
    $(document).on('ajaxComplete ajaxError', refresh);
})();
