﻿// Handles calculating how many occurrences of an apointment there will be given the:
// - Repeat type e.g. daily, weekly, monthly
// - Repeat interval
// - Maximum occurrences
// - Start date
// - End date
// - for Weekly appointments - what days they fall on
// Relies on moment.js

_.extend(window.timelyOccurrenceCalculator = window.timelyOccurrenceCalculator || {}, (function (document) { // eslint-disable-line no-unused-vars

    var internals = {};
    internals.repeatTypes = {
        daily: 'days',
        weekly: 'weeks',
        monthly: 'months'
    };

    //Returns a count of all the occurrences of an appointment given the conditions passed in
    function getOccurrences(repeatType, interval, max, startDate, endDate, weekDays) {
        var mStart = moment(startDate);
        var mEnd = moment(endDate);
        var mCurrent = moment(mStart);
        max = parseInt(max, 10);

        //If a max number of occurrences has been specified and no enddate is given, have to guess
        //and just return the max number
        if (!mEnd.isValid() && max && _.isNumber(max) && !_.isNaN(max)) return max;


        if (repeatType == 'weekly') {
            return _getWeeklyOccurrences(mStart, mEnd, mCurrent, repeatType, interval, weekDays, max);
        } else {
            return _getNormalOccurrences(mStart, mEnd, mCurrent, repeatType, interval, max);
        }
    }

    //Handles counting occurrences for daily and monthly repeats
    function _getNormalOccurrences(mStart, mEnd, mCurrent, repeatType, interval, max) {
        var numOccurrences = 0;
        var noMax = true;

        if (max && _.isNumber(max) && !_.isNaN(max)) noMax = false;

        if (noMax) max = 1000; //Safety check to ensure we dont go into an endless loop

        //We need to loop through following the rules creating occurrences until we hit a stop condition
        while ( numOccurrences < max && ( mCurrent.isBefore(mEnd) || mCurrent.isSame(mEnd))) {
            numOccurrences++;
            var checkDate = mCurrent.clone();
            mCurrent.add(internals.repeatTypes[repeatType], interval);
            if (checkDate.isSame(mCurrent)) {
                //Daylight savings silly buggers
                mCurrent.add('hours', 5);
                mCurrent.add(internals.repeatTypes[repeatType], interval);
            }
        }
        return numOccurrences;
    }

    //Handles the more tricky situation of counting occurrences when the repeatType is weekly
    function _getWeeklyOccurrences(mStart, mEnd, mCurrent, repeatType, interval, weekDays, max) {
        var numOccurrences = 0;
        var endOfFirstWeek = moment(mStart).endOf('week');
        var endOfLastWeek = moment(mEnd).endOf('week');
        var dayOfWeek = moment(mCurrent);
        var noMax = true;
        if (max && _.isNumber(max) && !_.isNaN(max)) noMax = false;

        if (noMax) max = 1000; //Safety check to ensure we dont go into an endless loop

        //if weekDays is empty assume the only day that the appointment occurs on is the same as the start day
        if (!weekDays || weekDays.length == 0) weekDays = [mStart.format("dddd")];

        //First we need to find out how many occurrences will occur during the starting week
        while ( numOccurrences < max && (dayOfWeek.isBefore(endOfFirstWeek) || dayOfWeek.isSame(endOfFirstWeek))) {
            var checkDate = dayOfWeek.clone();
            var todaysName = dayOfWeek.format("dddd");
            //if current day is in weekDays, add an occurrence
            if (_.contains(weekDays, todaysName)) {
                numOccurrences++;
            }
            dayOfWeek.add('days', 1);
            if (checkDate.isSame(dayOfWeek)) {
                //Daylight savings silly buggers
                dayOfWeek.add('hours', 5);
                dayOfWeek.add('days', 1);
            }
        }

        //Jump to the next week we need to add occurrences on
        mCurrent.add('weeks', interval);

        //Now start looping through the weeks always starting at the start of the week
        while ((noMax || numOccurrences < max) && (mCurrent.isBefore(endOfLastWeek) || mCurrent.isSame(endOfLastWeek))) {

            dayOfWeek = moment(mCurrent).startOf('week');

            //Loop through each day of the week incrementing the num occurrences each time you
            //hit a day that has been specified in weekDays
            var i = 0;
            while ((noMax || numOccurrences < max) && (i < 7 && (dayOfWeek.isBefore(mEnd) || dayOfWeek.isSame(mEnd)))) {
                // eslint-disable-next-line no-redeclare
                var todaysName = dayOfWeek.format("dddd");
                //if current day is in weekDays, add an occurrence
                if (_.contains(weekDays, todaysName)) {
                    numOccurrences++;
                }
                dayOfWeek.add('days', 1);
                i++;
            }
            mCurrent.add('weeks', interval);
        }
        return numOccurrences;

    }

    //Handles updating the recurrence ui on the busy/booking/class modals (assumes a lot about the html structure)
    function _updateModalDisplay(startDate, callback, startingOccurrences) {
        var recurrenceType = $('.recurrence-type').val();
        // eslint-disable-next-line no-redeclare
        var interval, max, startDate, endDate, weekDays, numOccurrences;
        var repeatType = $('#CalendarRecurrenceModel_CalendarRecurrence_RepeatTypeId:checked').val();
        var neverEnds = repeatType == '3';

        if (!startingOccurrences && !neverEnds) {

            interval = $('#CalendarRecurrenceModel_CalendarRecurrence_Interval').val();
            if ($('#CalendarRecurrenceModel_CalendarRecurrence_RepeatTypeId').prop('checked')) {
                max = $('#CalendarRecurrenceModel_CalendarRecurrence_Occurrences').val();
            }

            startDate = moment(startDate);
            endDate = moment('');

            var endDateField = $('#CalendarRecurrenceModel_CalendarRecurrence_EndDate');
            if (!endDateField.prop('disabled')) {
                endDate = moment(endDateField.datepicker("getDate"));
            }

            if (repeatType == '1') {
                if (endDate > moment().add(18, 'months')) {
                    endDate = null;
                }
            }

            if (recurrenceType == '3') { // daily
                numOccurrences = window.timelyOccurrenceCalculator.getOccurrences('daily', interval, max, startDate, endDate);
            }
            else if (recurrenceType == '4') { // weekly
                weekDays = [];
                if ($('#CalendarRecurrenceModel_CalendarRecurrence_Monday').prop('checked')) weekDays.push('Monday');
                if ($('#CalendarRecurrenceModel_CalendarRecurrence_Tuesday').prop('checked')) weekDays.push('Tuesday');
                if ($('#CalendarRecurrenceModel_CalendarRecurrence_Wednesday').prop('checked')) weekDays.push('Wednesday');
                if ($('#CalendarRecurrenceModel_CalendarRecurrence_Thursday').prop('checked')) weekDays.push('Thursday');
                if ($('#CalendarRecurrenceModel_CalendarRecurrence_Friday').prop('checked')) weekDays.push('Friday');
                if ($('#CalendarRecurrenceModel_CalendarRecurrence_Saturday').prop('checked')) weekDays.push('Saturday');
                if ($('#CalendarRecurrenceModel_CalendarRecurrence_Sunday').prop('checked')) weekDays.push('Sunday');
                numOccurrences = window.timelyOccurrenceCalculator.getOccurrences('weekly', interval, max, startDate, endDate, weekDays);

            }
            else if (recurrenceType == '5') { // monthly
                numOccurrences = window.timelyOccurrenceCalculator.getOccurrences('monthly', interval, max, startDate, endDate);
            }
            else {
                numOccurrences = 1;
            }
        }
        else {
            numOccurrences = startingOccurrences;
        }

        if (numOccurrences > 1) {
            $('#number-of-repeats').show();
        } else {
            $('#number-of-repeats').hide();
        }

        $('#total-occurrences').html(numOccurrences);

        if (_.isFunction(callback)) callback(numOccurrences, neverEnds);

    }

    function setupModalDisplay(startDate, callback, startingOccurrences) {

        $('.recurrence-controls input, .recurrence-controls select').on('click change', function () {
            _updateModalDisplay(startDate, callback);
        });
        _updateModalDisplay(startDate, callback, startingOccurrences);

    }

    return {
        getOccurrences: getOccurrences,
        setupModalDisplay: setupModalDisplay,
        i: internals
    };

})(window.document || {}));
