(function () {
    
    var category = 'dashboard';
    var filterLookup = {
        1: 'Rolling 7 days',
        2: 'Rolling 14 days',
        3: 'Rolling 30 days',
        4: 'Rolling 365 days',
        5: 'Day view',
        6: 'Week view',
        7: 'Month view',
        8: 'Year view'
    };


    window.timelyEventHub.sub('dashboard.prev_date_period', function () {
        window.timelyGA.track({ category: category, action: 'browsed date period', label: 'prev' });
    });

    window.timelyEventHub.sub('dashboard.next_date_period', function () {
        window.timelyGA.track({ category: category, action: 'browsed date period', label: 'next' });
    });

    window.timelyEventHub.sub('dashboard.changed_tab', function (data) {
        var tab = data.tab === undefined ? 'Unknown' : data.tab;
        window.timelyGA.track({ category: category, action: 'changed tab', label: tab });
    });

    window.timelyEventHub.sub('dashboard.selected_filter', function (data) {
        var filter = data.filter === undefined ? 'Unknown' : filterLookup[data.filter];
        window.timelyGA.track({ category: category, action: 'selected filter', label: filter});
    });

}());