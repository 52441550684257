﻿
/*
* SaveRecurringHelper
* Detects if specified fields are modified and subsequently changes the normal
* save button into one that triggers a balloon asking if the changes should be saved
* for just this ocurrence or this and all future ocurrences
*
* Usage:
* $('.container-of-form').saveRecurringHelper(options)
*
* Options:
* isRecurring = is this event already a recurring appointment
* formSelector = selector string for the container that holds the form
* justThisSelector = selector string for the button that will save just this occurrence
* futureSelector = selector string for the button that will save this and all future occurrences
* fieldToChangeSelector = selector string input that determines whether just this or all future occurrences are saved
* saveRecurringSelector = selector string recurring save button
* saveNormalSelector = selector string for normal save button
* changeSelector = selector string that determines what elements are tracked
*
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*   modal-form.js
*   change-tracker.js
*/

(function ($) {



    $.widget("timely.saveRecurringHelper", {
        options: {
            isRecurring: false,
            defaultRecurringState: 'normal',
            formSelector: '',
            justThisSelector: '.just-this',
            futureSelector: '.this-and-future',
            fieldToChangeSelector: '#UpdateThisOnly',
            saveRecurringSelector: '.save-recurring',
            saveNormalSelector: '.save-normal',
            changeSelector: '.track-change'
        },

        _create: function () {

            var self = this,
            options = self.options;
            self.container = self.element;

            self._toggleSaveButton(options.defaultRecurringState);

            if (options.isRecurring) {
                $(document).off('.saveRecurringHelper');

                $(document).on('click.saveRecurringHelper', options.justThisSelector, function (e) {
                    e.preventDefault();
                    self._triggerSave("true");
                });

                $(document).on('click.saveRecurringHelper', options.futureSelector, function (e) {
                    e.preventDefault();
                    self._triggerSave("false");
                });

                $(options.formSelector).changeTracker({
                    selector: options.changeSelector,
                    onChange: function() {self._toggleSaveButton(options.isRecurring ? 'recurring' : options.defaultRecurringState);}
                });
            }

        },

        _triggerSave: function (splitOff) {
            var self = this,
            options = self.options;
            if ($(options.formSelector).modalForm('isValid')) {
                $(document).off('.saveRecurringHelper');
                $(options.fieldToChangeSelector).val(splitOff);
                $(options.formSelector).modalForm('submitForm');
            }
        },

        _toggleSaveButton: function (button) {
            var self = this;
            if (button == 'normal') {
                $(self.options.formSelector + ' ' + self.options.saveRecurringSelector).hide();
                $(self.options.formSelector + ' ' + self.options.saveNormalSelector).show();
            } else {
                $(self.options.formSelector + ' ' + self.options.saveRecurringSelector).show();
                $(self.options.formSelector + ' ' + self.options.saveNormalSelector).hide();
            }
        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) { // eslint-disable-line no-unused-vars
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);
