(function () {

    var category = 'two_factor_auth';

    // Settings

    window.timelyEventHub.sub('two_factor_auth.setup_started', function () {
        window.timelyGA.track({ category: category, action: 'setup_started', label: 'setup' });
    });

    window.timelyEventHub.sub('two_factor_auth.setup_completed', function () {
        window.timelyGA.track({ category: category, action: 'setup_completed', label: 'setup' });
    });

    window.timelyEventHub.sub('two_factor_auth.cancel_from_number_setup', function () {
        window.timelyGA.track({ category: category, action: 'cancel_from_number_setup', label: 'setup' });
    });

    window.timelyEventHub.sub('two_factor_auth.cancel_from_code_input', function () {
        window.timelyGA.track({ category: category, action: 'cancel_from_code_input', label: 'setup' });
    });

    window.timelyEventHub.sub('two_factor_auth.help_link', function () {
        window.timelyGA.track({ category: category, action: 'help_link', label: 'settings' });
    });

    window.timelyEventHub.sub('two_factor_auth.view_recovery_codes', function () {
        window.timelyGA.track({ category: category, action: 'view_recovery_codes', label: 'settings' });
    });

    window.timelyEventHub.sub('two_factor_auth.regenerate_recovery_codes', function () {
        window.timelyGA.track({ category: category, action: 'regenerate_recovery_codes', label: 'settings' });
    });

    window.timelyEventHub.sub('two_factor_auth.open_email_change_modal', function () {
        window.timelyGA.track({ category: category, action: 'open_email_change_modal', label: 'settings' });
    });

    window.timelyEventHub.sub('two_factor_auth.enabled', function () {
        window.timelyGA.track({ category: category, action: 'enabled', label: 'settings' });
    });

    window.timelyEventHub.sub('two_factor_auth.disabled', function () {
        window.timelyGA.track({ category: category, action: 'disabled', label: 'settings' });
    });

    window.timelyEventHub.sub('two_factor_auth.reset', function () {
        window.timelyGA.track({ category: category, action: 'reset', label: 'settings' });
    });

    // Login

    window.timelyEventHub.sub('two_factor_auth.skip', function () {
        window.timelyGA.track({ category: category, action: 'skip', label: 'login' });
    });

    window.timelyEventHub.sub('two_factor_auth.send_new_code', function () {
        window.timelyGA.track({ category: category, action: 'send_new_code', label: 'login' });
    });

    window.timelyEventHub.sub('two_factor_auth.enter_recovery_code', function () {
        window.timelyGA.track({ category: category, action: 'enter_recovery_code', label: 'login' });
    });

    window.timelyEventHub.sub('two_factor_auth.submit_recovery_code', function () {
        window.timelyGA.track({ category: category, action: 'submit_recovery_code', label: 'login' });
    });

    window.timelyEventHub.sub('two_factor_auth.logout', function () {
        window.timelyGA.track({ category: category, action: 'logout', label: 'login' });
    });

    window.timelyEventHub.sub('two_factor_auth.wrong_number', function () {
        window.timelyGA.track({ category: category, action: 'wrong_number', label: 'login' });
    });

    window.timelyEventHub.sub('two_factor_auth.login_help', function () {
        window.timelyGA.track({ category: category, action: 'login_help', label: 'login' });
    });

}());
