(function () {

    var category = 'services';
    
    window.timelyEventHub.sub('multiple_resources.save', function () {
        window.timelyGA.track({ category: category, action: 'save_multiresource_service', label: 'multiple_resources_saved' });
    });

    window.timelyEventHub.sub('multiple_resources.reorder', function () {
        window.timelyGA.track({ category: category, action: 'reorder_resources', label: 'multiple_resources_reordered'});
    });
}());
