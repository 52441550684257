//---------------------------------------------------------------------------------------------
//A controller that powers the add appointment / sale buttons in the nav
//---------------------------------------------------------------------------------------------
; // eslint-disable-line no-extra-semi
(function() {
    if (!window.NavButtonsModules) window.NavButtonsModules = {};

    window.NavButtonsModules.NavButtonsController = function (options) {

        var defaults = {
            addInvoiceUrl: '/billing/invoiceadd',
            addInvoiceModalClass: 'invoice-modal',
            staffId: 0,
            locationId: 0,
            addConcessionUrl: '/customer/concessionadd',
            addGiftVoucherUrl: '/customer/giftvoucheradd',
            addCreditUrl: '/customer/creditadd',
            addPettyCashUrl: '/pettycash/addpettycash'
        };

        //This object should be used to store all data and functions that should be private,
        //the only time external js should be accessing
        //the internals object is during unit tests
        var internals = {
        };

        function _hasCalendarV1() {
            return typeof window.theCalendarController != 'undefined';
        }

        /**
         * Detect if the API for v2 of the calendar is available.
         */
        function _hasCalendarV2() {
            return typeof window.calendarV2Api !== 'undefined';
        }

        function _getStaffAndLocation() {
            var staffId = internals.options.staffId;
            var locationId = internals.options.locationId;
            if (_hasCalendarV1()) {
                staffId = window.theCalendarController.isStaffId(window.theCalendarController.currentCalendarResourceId) ? window.theCalendarController.currentCalendarResourceId : staffId ;
                locationId = window.theCalendarController.currentLocationId;
            } else if (_hasCalendarV2()) {
                var calendarStaffId = window.calendarV2Api.getCurrentStaff();
                if (calendarStaffId) {
                    staffId = calendarStaffId;
                }

                locationId = window.calendarV2Api.getCurrentLocation();
            }

            return {
                staffId: staffId,
                locationId: locationId
            }
        }

        function _toCustomerParam(el) {
            var customerId = el.data().customerId;
            return customerId ? '&customerId=' + customerId : '';
        }

        function _handleAddBooking(event, el) {

            // If no version of the calendar is loaded, the event's default action
            // will send the user to the correct calendar with its "New appointment" mode active

            var customerId = el.data().customerId;

            if (_hasCalendarV1()) {
                event.preventDefault();

                if (customerId) window.bookController.customerId = customerId;
                window.bookController.action = 'new-booking';
                window.bookController.bookingId = -1;
                window.bookController.message.html('Choose a time for the new appointment. <a class="rebook-cancel">&times</a>');
                window.bookController.message.show();
                $('.rebook-cancel', window.bookController.message).on('click', function() {
                    window.bookController.cancel();
                });
                window.theCalendarController.calendar.fullCalendar('option', 'height', window.theCalendarController.getHeight());
            } else if (_hasCalendarV2()) {
                event.preventDefault();

                var options = {
                    type: 'new-appointment',
                };
                if (customerId) {
                    options.customerId = customerId;
                }

                window.calendarV2Api.enterTimeSelectionMode(options);
            }
        }

        function _handleAddCredit(el) {
            $('.modal-backdrop').click();
            var staffLocation = _getStaffAndLocation();
            var showOptions = {
                href: internals.options.addCreditUrl + '?staffId=' + (staffLocation.staffId > 0 ? staffLocation.staffId : '') + (staffLocation.locationId > 0 ? '&locationId=' + staffLocation.locationId : '') + _toCustomerParam(el),
                modalClass: 'customer-credit-modal'
            };
            $(document).modal('showManual', showOptions);
        }

        function _handleAddPettyCash(el) {
            $('.modal-backdrop').click();
            var staffLocation = _getStaffAndLocation();
            var showOptions = {
                href: internals.options.addPettyCashUrl + '?staffId=' + (staffLocation.staffId > 0 ? staffLocation.staffId : '') + (staffLocation.locationId > 0 ? '&locationId=' + staffLocation.locationId : '') + _toCustomerParam(el),
                modalClass: 'petty-cash-modal'
            };
            $(document).modal('showManual', showOptions);
        }

        function _handleAddGiftVoucher(el) {
            $('.modal-backdrop').click();
            var staffLocation = _getStaffAndLocation();
            var showOptions = {
                href: internals.options.addGiftVoucherUrl + '?staffId=' + (staffLocation.staffId > 0 ? staffLocation.staffId : '') + (staffLocation.locationId > 0 ? '&locationId=' + staffLocation.locationId : '') + _toCustomerParam(el),
                modalClass: 'customer-gift-voucher-modal'
            };
            $(document).modal('showManual', showOptions);
        }

        function _handleAddConcession(el) {
            $('.modal-backdrop').click();
            var staffLocation = _getStaffAndLocation();
            var showOptions = {
                href: internals.options.addConcessionUrl + '?staffId=' + (staffLocation.staffId > 0 ? staffLocation.staffId : '') + (staffLocation.locationId > 0 ? '&locationId=' + staffLocation.locationId : '') + _toCustomerParam(el),
                modalClass: 'customer-concession-modal'
            };
            $(document).modal('showManual', showOptions);
        }

        function _handleAddInvoice(el, isServiceDefault) {
            $('.modal-backdrop').click();
            var staffLocation = _getStaffAndLocation();
            var queryJoiner = internals.options.addInvoiceUrl.indexOf('?') > -1 ? '&' : '?';
            var showOptions = {
                href: internals.options.addInvoiceUrl + queryJoiner +
                    'updateCalendar=true&staffId=' + staffLocation.staffId +
                    (staffLocation.locationId > 0 ? '&locationId=' + staffLocation.locationId : '') +
                    _toCustomerParam(el) +
                    (isServiceDefault ? '&isServiceDefault=true' : ''),
                modalClass: internals.options.addInvoiceModalClass
            };
            $(document).modal('showManual', showOptions);
        }

        function _handleAddProduct(link) {
            _handleAddInvoice(link, false);
        }

        function _handleAddService(link) {
            _handleAddInvoice(link, true);
        }

        function _closeMobileMenu() {
            if (window.timelyEventHub) {
                timelyEventHub.pub('mobile_menu.close')
            }
        }

        function _bindEvents() {

            function openNewSale(ele, tab, dontLoadCustomer) {
                if (window.Sale && window.Sale.Use()) {
                    window.Sale.Open({
                        tab: tab,
                        customerId: !dontLoadCustomer ? ele.data().customerId : null,
                        onClose: function () {
                            if (_hasCalendarV2()) {
                                window.calendarV2Api.refresh();
                            } else {
                                $('.refresh').click();
                            }
                        }
                    });
                    return true;
                }
                return false;
            }

            $('.add-sale').on('click', function (e) {
                if (window.Sale && window.Sale.Use()) {
                    e.preventDefault();
                    e.stopPropagation();

                    var selectedCustomerId = undefined;
                    if (location.pathname.toLowerCase().indexOf('customer/customers') > 0) {
                        selectedCustomerId = parseInt(localStorage.getItem("customerId"));
                    }
                    // ensure dropdowns are closed (eg: reports dropdowns on invoices)
                    $('.dropdown-toggle').parent().removeClass('open');
                    window.Sale.Open({
                        tab: 'appointments',
                        customerId: selectedCustomerId,
                        onClose: function () {
                            if (_hasCalendarV2()) {
                                window.calendarV2Api.refresh();
                            } else {
                                $('.refresh').click();
                            }
                        }
                    });
                }
            });

            $('.timely-nav__links .btn-group').on('hover', function () {
                if($(window).width() < 940) return;
                $('.timely-navbar .btn-group.open').removeClass('open');
            });

            $(document).on('click', '.add-booking', function (e) {
                _handleAddBooking(e, $(this));
                _closeMobileMenu();
            });

            $(document).on('click', '.add-invoice', function (e) {
                e.preventDefault();
                if (!openNewSale($(this), 'products')) {
                    _handleAddProduct($(this));
                }
                _closeMobileMenu();
            });

            $(document).on('click', '.add-walkin-service', function (e) {
                e.preventDefault();
                if (!openNewSale($(this), 'services')) {
                    _handleAddService($(this));
                }
                _closeMobileMenu();
            });

            $(document).on('click', '.add-concession', function (e) {
                e.preventDefault();
                if (!openNewSale($(this), 'packages')) {
                    _handleAddConcession($(this));
                }
                _closeMobileMenu();
            });

            $(document).on('click', '.add-gift-voucher-nav', function (e) {
                e.preventDefault();
                if (!openNewSale($(this), 'vouchers')) {
                    _handleAddGiftVoucher($(this));
                }
                _closeMobileMenu();
            });

            $(document).on('click', '.add-credit-nav', function (e) {
                e.preventDefault();
                if (!openNewSale($(this), 'credit')) {
                    _handleAddCredit($(this));
                }
                _closeMobileMenu();
            });

            $(document).on('click', '.add-petty-cash-nav', function (e) {
                e.preventDefault();
                if (!openNewSale($(this), 'appointments', true)) {
                    _handleAddPettyCash($(this));
                }
                _closeMobileMenu();
            });
        }

        function _init() {
            internals.options = $.extend({}, defaults, options);
            _bindEvents();
        }

        _init();

        return {
            i: internals
        };
    };
}());
