/*
 * Modal Window Plugin
 * Allows easy use of modal windows with content provided from a url
 * Usage:
 * Add class of modal to an anchor element.
 * When the modal opens it will retrieve it's contents from the anchor tag's href url
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   change-tracker.js
 */
/* eslint no-unused-vars: 0 */
(function ($) {

    var modalOverlay = '<div class="modal-backdrop"></div>';
    var modalContainer = '<div class="modal"></div>';
    var errorIframeTemplate = '<a class="close modal-close" href="javascript:void(0);" style="position: absolute;top: 3px;right: 9px;color: #666!important;">×</a><iframe src="{SOURCE}" style="width: 580px; height: 480px; border: none; margin-bottom:-7px"></iframe>';
    var overlayed = false;

    $.widget("timely.modal", {
        options: {
            optionVar: 60
        },

        _create: function () {
            var self = this,
                options = self.options;
            self.modalClass = '';
            $('body').append(modalContainer);
            self.container = $('.modal');

            if (!('ontouchstart' in document.documentElement)) self.container.draggable({ handle: ".modal-header" });

            self._setup();
            self.isModified = false;

        },

        _toggleBackdrop: function (callback) {
            var self = this;

            if (overlayed) {
                self.backdrop = $(modalOverlay).appendTo(document.body);
                self.backdrop.addClass('in');
                callback();
            } else if (!overlayed && self.backdrop) {
                self.backdrop.removeClass('in');
                self._removeBackdrop();
            } else if (callback) {
                callback();
            }
        },

        _removeBackdrop: function () {
            var self = this;
            self.backdrop.remove();
            self.backdrop = null;
        },

        showFromLink: function (element) {
            var self = this;

            var salesTypes = {
                Standard: "appointments",
                Products: "products",
                Packages: "packages",
                GiftVouchers: "vouchers",
                Credit: "credit",
                Invoice : "invoice",
                Draft : "draft"
            };
            function getLinkInfo(href, classNames) {
                var querystring = href.substring(href.indexOf('?') + 1).toLowerCase();
                var result = {
                    isSales: false,
                    salesType: null,
                    customerId: null,
                    packageId: null,
                    giftVoucherId: null,
                    bookingGroupId: null,
                    invoiceId: null,
                    draftId : null,
                    tab: function () {
                        return this.salesType;
                    }
                }
                if (href.indexOf('invoiceadd') > -1) {
                    result.salesType = salesTypes.Products;
                } else if (href.indexOf('concessionadd') > -1) {
                    result.salesType = salesTypes.Packages;
                } else if (href.indexOf('draftid') > -1) {
                    result.salesType = salesTypes.Draft;
                } else if (href.indexOf('giftvoucheradd') > -1) {
                    result.salesType = salesTypes.GiftVouchers;
                } else if (href.indexOf('creditadd') > -1) {
                    result.salesType = salesTypes.Credit;
                } else if (href.indexOf('bookinggroupid') > -1) {
                    result.salesType = salesTypes.Standard;
                } else if (href.indexOf('invoiceid') > -1 && href.indexOf('paymentadd') > -1 && classNames.indexOf('invalid-for-new-sales-flow') === -1) {
                    result.salesType = salesTypes.Invoice;
                }  else {
                    result.isSales = false;
                    return result;
                }
                result.isSales = true;

                if (result.isSales && URLSearchParams) {
                    var searchParams = new URLSearchParams(querystring);
                    result.customerId = searchParams.has('customerid') ? parseInt(searchParams.get('customerid')) : null;
                    result.packageId = searchParams.has('concessionid') ? parseInt(searchParams.get('concessionid')) : null;
                    result.giftVoucherId = searchParams.has('giftvoucherid') ? parseInt(searchParams.get('giftvoucherid')) : null;
                    result.groupId = searchParams.has('bookinggroupid') ? parseInt(searchParams.get('bookinggroupid')) : null;
                    result.invoiceId = searchParams.has('invoiceid') ? parseInt(searchParams.get('invoiceid')) : null;
                    result.draftId = searchParams.has('draftid') ? parseInt(searchParams.get('draftid')) : null;
                }
                return result;
            }

            var href = element[0].nodeName === "A" ? element.attr('href') : element.data('href');
            var classNames = (element.attr('class') || '').split(' ');

            var linkInfo = getLinkInfo(href.toLowerCase(), classNames);

            //special handling for links pointing at the sales modal
            if (linkInfo.isSales && window.Sale && window.Sale.Use()) {
                window.Sale.Open({
                    tab: linkInfo.tab(),
                    customerId: linkInfo.customerId,
                    packageId: linkInfo.packageId,
                    giftVoucherId: linkInfo.giftVoucherId,
                    groupId : linkInfo.groupId,
                    invoiceId : linkInfo.invoiceId,
                    draftId : linkInfo.draftId,
                    onClose: function () {
                        if (window.calendarV2Api) {
                            window.calendarV2Api.refresh();
                        } else {
                            $('.refresh').click();
                        }
                    }
                });
            } else {
                var showOptions = {
                    href: href,
                    outputClass: element.data('outputClass'),
                    isAddOperation: element.data('isAddOperation') || "no",
                    modalClass: element.data('modalClass') || ""
                };
                self.showManual(showOptions);
            }
        },

        resetModified: function () {
            var self = this;
            self.isModified = false;
        },

        showManual: function (showOptions) {

            var self = this;
            var dataUrl = showOptions.href;
            var outputClass = showOptions.outputClass;
            var isAddOperation = showOptions.isAddOperation || "no";

            self.modalClass = showOptions.modalClass || self.modalClass;
            self.isModified = false;
            //Need to reset the position if a previous modal had been dragged
            self.container[0].removeAttribute("style");

            overlayed = true;
            $('body').addClass('modal-open');

            self.container[0].innerHTML = '';

            self._toggleBackdrop.call(self, function () {

                self.backdrop.showLoading({ overlayClass: 'overlay-hidden' });

                $.get(dataUrl, function (data) {

                    //Check if we need to redirect to the login screen
                    if (data && data.indexOf("TimelyLoggedOutSite") != -1) {
                        window.location = '/Account/Login';
                    }
                    else if (data && data.indexOf("TimelyErrorPage") != -1 && data.indexOf("TimelyUnauthorisedPage") == -1) {
                        self.backdrop.hideLoading();
                        var iframeSrc = '/error';
                        self.container.empty().html(errorIframeTemplate.replace("{SOURCE}", iframeSrc));
                        self.container.show();
                    }
                    else {
                        self.backdrop.hideLoading();

                        self.container.empty().html(data);
                        self.container.addClass(self.modalClass);
                        self.container.show();

                        self.container.data('outputClass', outputClass);
                        self.container.data('isAddOperation', isAddOperation);

                        var windowheight = $(window).height();
                        var contHeight = self.container.height() + 40;
                        if (contHeight > windowheight) {
                            var top = $(window).scrollTop();
                            self.container.css({ 'margin-top': 0, top: top, position: 'absolute' });
                        }

                        self.container.changeTracker({ onChange: function() {
                            self.isModified = true;
                        } });
                    }
                });
            });
        },

        _setup: function () {

            var self = this,
                options = self.options;

            $(document).on("click.nl", ".modal-open", function (event) {
                event.preventDefault();
                if (overlayed) return;
                self.showFromLink($(this));
            });


            $(document).on("click.nl", ".modal", function (event) {
                event.stopPropagation();
            });

            $(document).on('keyup', (function (e) {
                if (e.keyCode == 27) {
                    $('.modal-backdrop').click();
                }
            }));

            $(document).on('click', '.modal-close, .modal-backdrop', function (event) {
                event.stopPropagation();
                if($('.tooltip').length > 0) {
                    $('.tooltip').hide();
                }

                if ($('.cta-modal').length > 0) {
                    return;
                }

                if (!self.confirmCloseModal()) {
                    return;
                }

                var modalOutput = $('.modal-output', self.container).children();

                overlayed = false;

                $('body').removeClass('modal-open');
                self.container.removeClass('in');

                $.support.transition && self.backdrop && self.backdrop.hasClass('fade') ?
                    self.hideWithTransition() :
                    self.hideModal();

                //deal with any output if neccesary
                if (modalOutput.length > 0) {

                    //hide any alert boxes on the page
                    $('.alert-block').hide();

                    $('.thumbnails').show();

                    //Stick any output into the defined output element
                    var outputClass = self.container.data('outputClass');

                    if (_.isString(outputClass) && outputClass.length > 0){
                        //Check if there has been a new output defined
                        var changedOutputClass = $('.modal-output', self.container).data().newOutputClass;

                        var changedOutputElement;
                        var hasChangedOutput = false;

                        if (!_.isUndefined(changedOutputClass) && changedOutputClass != outputClass) {
                            changedOutputElement = $('.' + changedOutputClass).not('.modal .' + changedOutputClass);
                            hasChangedOutput = true;
                        }

                        var outputElement = $('.' + outputClass).not('.modal .' + outputClass);

                        var isAddOperation = self.container.data('isAddOperation') == 'yes';

                        // Check if we are adding something new or editing an element that already exists
                        if (isAddOperation) {

                            if (hasChangedOutput) {
                                outputElement = changedOutputElement;
                                var newOutput = outputElement.clone();
                                outputElement.after(newOutput);
                                outputElement.html(modalOutput);
                                outputElement.removeClass(outputClass);
                                modalOutput.unwrap();
                                modalOutput.hide();
                                modalOutput.addClass('run-slide-animation');
                            } else {
                                // eslint-disable-next-line no-redeclare
                                var newOutput = outputElement.clone();
                                outputElement.after(newOutput);
                                outputElement.html(modalOutput);
                                outputElement.removeClass(outputClass);
                                newOutput.empty();
                                modalOutput.hide();
                                modalOutput.show();
                                modalOutput.addClass('run-slide-animation');
                            }

                        } else {

                            if (hasChangedOutput) {
                                //need to hide original element
                                outputElement.addClass('run-slide-down-no-shrink-animation');
                                setTimeout(function() {

                                    outputElement.parent().remove();
                                    var sortOfNewOutput = changedOutputElement.clone();
                                    changedOutputElement.after(sortOfNewOutput);
                                    changedOutputElement.empty().append(modalOutput);
                                    modalOutput.unwrap();
                                    modalOutput.hide();
                                    modalOutput.show();
                                    modalOutput.addClass('run-slide-animation');

                                }, 600);

                            } else {

                                outputElement.addClass('run-slide-down-no-shrink-animation');
                                setTimeout(function () {

                                    //stick the new content into the designated output element
                                    outputElement.empty().append(modalOutput);
                                    //Unwrap the output element containing the new content, effectively replacing it
                                    modalOutput.unwrap();
                                    modalOutput.hide();
                                    modalOutput.show();
                                    modalOutput.addClass('run-slide-animation');

                                }, 600);

                            }

                        }

                    }

                }

            });

        },

        confirmCloseModal: function() {
            return this.isModified ? confirm("You've made unsaved changes. Are you sure you want to leave this page?") : true;
        },

        hideWithTransition: function () {
            var self = this;
            var timeout = setTimeout(function () {
                self.container.off($.support.transition.end);
                self.hideModal();
            }, 1);

            self.container.one($.support.transition.end, function () {
                clearTimeout(timeout);
                self.hideModal();
            });
        },

        hideOnConfirmation: function(isOverlayed) {
            var shouldProceed = this.confirmCloseModal();

            if (shouldProceed) {
                this.hideModal(isOverlayed);
            }

            return shouldProceed;
        },

        hideModal: function (isOverlayed) {
            var self = this;
            var actuallyHide = true;
            if (isOverlayed) overlayed = false;

            $("loading-indicator-overlay").hide();
            self.container.hide();
            self.container.removeClass(self.modalClass);
            self.modalClass = '';
            self._toggleBackdrop(function () { });

            if (window.ReactDOM) {
                $.each(self.container.find('.react-container-node'), function (key, containerNode) {
                    window.ReactDOM.unmountComponentAtNode(containerNode);
                });
            }

            //Clear out any iframes
            self.container.find('iframe').remove();
        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);
