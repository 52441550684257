(function () {

    var category = 'stock';
   
    window.timelyEventHub.sub('stock.bulk_adjust', function () {
        window.timelyGA.track({ category: category, action: 'bulk_adjust', label: 'products' });
    });

    window.timelyEventHub.sub('stock.create_order', function () {
        window.timelyGA.track({ category: category, action: 'create', label: 'order' });
    });

    window.timelyEventHub.sub('stock.clone_order', function () {
        window.timelyGA.track({ category: category, action: 'clone', label: 'order' });
    });

    window.timelyEventHub.sub('stock.edit_order', function () {
        window.timelyGA.track({ category: category, action: 'edit', label: 'order' });
    });

    window.timelyEventHub.sub('stock.receipt_order', function () {
        window.timelyGA.track({ category: category, action: 'receipt', label: 'order' });
    });
    
    window.timelyEventHub.sub('stock.cancel_order', function () {
        window.timelyGA.track({ category: category, action: 'cancel', label: 'order' });
    });

    window.timelyEventHub.sub('stock.uncancel_order', function () {
        window.timelyGA.track({ category: category, action: 'uncancel', label: 'order' });
    });

    window.timelyEventHub.sub('stock.send_order', function () {
        window.timelyGA.track({ category: category, action: 'send', label: 'order' });
    });

    window.timelyEventHub.sub('stock.receipt_from_stock_list', function () {
        window.timelyGA.track({ category: category, action: 'receipt_from_stock', label: 'order' });
    });

    window.timelyEventHub.sub('stock.modified_on_receipt', function () {
        window.timelyGA.track({ category: category, action: 'modified_on_receipt', label: 'order' });
    });

    window.timelyEventHub.sub('stock.manual_product_quantity_change', function (data) {
        var suffix = data.stockId > 0 ? '' : '_on_add';
        window.timelyGA.track({ category: category, action: 'manual_product_quantity_change' + suffix, label: data.type });
    });

    window.timelyEventHub.sub('stock.load_existing_stock_order', function (existingOrder) {
        var productsCount = existingOrder && existingOrder.products && existingOrder.products.length ? existingOrder.products.length : 0;
        window.timelyGA.track({ category: category, action: 'load_existing_stock_order', label: productsCount });
    });

    window.timelyEventHub.sub('stock.ignore_existing_stock_order', function (existingOrder) {
        var productsCount = existingOrder && existingOrder.products && existingOrder.products.length ? existingOrder.products.length : 0;
        window.timelyGA.track({ category: category, action: 'ignore_existing_stock_order', label: productsCount });
    });

    window.timelyEventHub.sub('stock.add_all_to_order', function (filter) {
        window.timelyGA.track({ category: category, action: 'add_all_to_order', label: filter });
    });

    window.timelyEventHub.sub('stock.manual_stock_adjustment', function (adjustmentType) {
        window.timelyGA.track({ category: category, action: 'manual_stock_adjustment', label: adjustmentType })
    });

    window.timelyEventHub.sub('stock.click_manage_stock_levels', function () {
        window.timelyGA.track({ category: category, action: 'click_manage_stock_levels', label: 'products_page' });
    });
}());
