(function () {
    var category = "consultation";

    window.timelyEventHub.sub("consultation.send_consultation", function (data) {
        window.timelyGA.track({ category: category, action: "send_consultation", label: data.sendConsultation, });
    });

    window.timelyEventHub.sub("consultation.form_builder_form_submitted", function (data) {
            window.timelyGA.track({ category: category, action: "form_builder_form_submitted", label: data.submissionType, });
    });

    window.timelyEventHub.sub("consultation.duplicate_sms_number", function () {
        window.timelyGA.track({ category: category, action: "duplicate_sms_number", label: "duplicate_sms_number_error", });
    });

    window.timelyEventHub.sub('consultation.test_send_consultation_started', function () {
        window.timelyGA.track({ category: category, action: 'test_send_consultation', label: 'started', });
    });

    window.timelyEventHub.sub('consultation.test_send_consultation_submitted', function () {
        window.timelyGA.track({ category: category, action: 'test_send_consultation', label: 'submitted', });
    });

    window.timelyEventHub.sub("consultation.rule_form_submitted", function (data) {
        window.timelyGA.track({ category: category, action: "rule_form_submitted", label: data.submissionType, });
    });

    window.timelyEventHub.sub("consultation.rules_upsell_clicked", function () {
        window.timelyGA.track({ category: category, action: "rules_upsell_clicked" });
    });

}());
