/*
* Timely Balloon plugin
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

/* eslint no-unused-vars: 0 */
(function ($) {

    var balloonTemplate = '<div class="popover"><div class="arrow"></div><div class="popover-inner"><h3 class="popover-title"></h3><div class="popover-content"><div></div></div></div></div>';
    var backingTemplate = '<div class="balloon-backing"></div>';

    $.widget("time.balloon", {
        options: {
            title: '',
            content: '',
            placement: 'right',
            balloonClass: '',
            afterOpen: function () {
            },
            onClose: null
        },

        _create: function () {
            var self = this;
            self.initialized = false;
        },

        show: function (ele, opts) {
            var self = this,
                pos,
                actualWidth,
                actualHeight,
                placement,
                tp;

            if (self.initialized) {
                self.popBalloon();
            }

            self.balloon = $(balloonTemplate);
            self.element = ele;
            self.options = $.extend(self.options, opts);

            self.addBacking();
            self.setContent();

            placement = typeof self.options.placement == 'function' ?
                self.options.placement.call(self, self.balloon[0], self.element[0]) :
                self.options.placement;

            self.balloon
                .remove()
                .css({ top: 0, left: 0, display: 'block', 'z-index': 5001 })
                .appendTo(document.body);

            $('.bln-close', self.balloon).on('click.balloon', function (e) {
                self.popBalloon();
            });

            $(document).on("click.balloon", ".popover-inner", function (event) {
                event.stopPropagation();
            });


            $(document).on("keyup.balloon", function (e) {
                if (e.keyCode == 27) {
                    self.popBalloon();
                }
            });

            pos = self.getPosition();

            actualWidth = self.balloon[0].offsetWidth;
            actualHeight = self.balloon[0].offsetHeight;

            tp = self.correctPlacement(placement, pos, actualWidth, actualHeight);

            self.balloon
                .css(tp)
                .addClass(tp.placement)
                .addClass('in');

            self.initialized = true;

            self.options.afterOpen(self.balloon);
            self.options.afterOpen = function () { };
        },

        correctPlacement: function (placement, pos, actualWidth, actualHeight) {
            var self = this,
                tp;

            tp = self.setCoords(placement, pos, actualWidth, actualHeight);

            var windowWidth = $(window).width();
            var windowHeight = $(window).height();
            var scrollTop = $(window).scrollTop();
            var docHeight = $(document).height();

            //check within window bounds
            switch (placement) {
                case 'bottom':
                    if (tp.top + actualHeight > windowHeight) {
                        tp = self.setCoords('top', pos, actualWidth, actualHeight);
                    }
                    if (tp.top < 0) {
                        tp = self.setCoords('left', pos, actualWidth, actualHeight);
                    }
                    if (tp.left < 0) {
                        tp = self.setCoords('right', pos, actualWidth, actualHeight);
                    }
                    break;
                case 'top':
                    if (tp.top < 0) {
                        tp = self.setCoords('bottom', pos, actualWidth, actualHeight);
                    }
                    if (tp.top + actualHeight > windowHeight) {
                        tp = self.setCoords('left', pos, actualWidth, actualHeight);
                    }
                    if (tp.left < 0) {
                        tp = self.setCoords('right', pos, actualWidth, actualHeight);
                    }
                    break;
                case 'left':
                    if (tp.left < 0) {
                        tp = self.setCoords('right', pos, actualWidth, actualHeight);
                    }
                    if (tp.left + actualWidth > windowWidth) {
                        tp = self.setCoords('top', pos, actualWidth, actualHeight);
                    }
                    if (tp.top < 0) {
                        tp = self.setCoords('bottom', pos, actualWidth, actualHeight);
                    }
                    break;
                case 'right':
                    if (tp.left + actualWidth > windowWidth) {
                        tp = self.setCoords('left', pos, actualWidth, actualHeight);
                    }
                    if (tp.left < 0) {
                        tp = self.setCoords('top', pos, actualWidth, actualHeight);
                    }
                    if (tp.top < 0) {
                        tp = self.setCoords('bottom', pos, actualWidth, actualHeight);
                    }

                    if (tp.top + actualHeight > docHeight) {
                        tp = self.setCoords('top', pos, actualWidth, actualHeight);

                        if(tp.top < 0) {
                            tp = self.setCoords('top', pos, actualWidth, actualHeight - Math.abs(tp.top));
                        }
                    }

                    if (tp.left < 0) {
                        //in a tight spot probably down in the lower left corner
                        //so will have to just move the balloon to the right and accept it wont look perfect
                        tp.left = 0;
                        self.balloon.find('.arrow').css({ left: '25%'});
                    }

                    break;
            }
            return tp;
        },



        setCoords: function (placement, pos, actualWidth, actualHeight) {
            var self = this;
            var tp = {};

            switch (placement) {
                case 'bottom':
                    tp = { top: pos.top + pos.height, left: pos.left + pos.width / 2 - actualWidth / 2, placement: 'bottom' };
                    break;
                case 'top':
                    tp = { top: pos.top - actualHeight, left: pos.left + pos.width / 2 - actualWidth / 2, placement: 'top' };
                    break;
                case 'left':
                    tp = { top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left - actualWidth, placement: 'left' };
                    break;
                case 'right':
                    tp = { top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left + pos.width, placement: 'right' };
                    break;
            }
            return tp;
        },

        getPosition: function () {
            var self = this;
            return $.extend({}, (self.element.offset()), {
                width: self.element[0].offsetWidth,
                height: self.element[0].offsetHeight
            });
        },

        setContent: function () {
            var self = this,
                title = self.getTitle(),
                content = self.getContent(),
                balloonClass = self.getBalloonClass();
            self.balloon.find('.popover-inner').addClass(balloonClass);
            self.balloon.find('.popover-title')[$.type(title) == 'object' ? 'append' : 'html'](title);
            self.balloon.find('.popover-content > *')[$.type(content) == 'object' ? 'append' : 'html'](content);
        },

        getContent: function () {
            var self = this,
                content,
                options = self.options;
            content = self.element.attr('data-content') || (typeof options.content == 'function' ? options.content.call(self.element[0]) : options.content);
            content = content.toString().replace(/(^\s*|\s*$)/, "");
            return content;
        },

        getTitle: function () {
            var self = this,
                title,
                options = self.options;
            title = self.element.attr('data-original-title') || (typeof options.title == 'function' ? options.title.call(self.element[0]) : options.title);
            title = (title || '').toString().replace(/(^\s*|\s*$)/, "");
            return title;
        },

        getBalloonClass: function () {
            var self = this,
                balloonClass,
                options = self.options;
            balloonClass = self.element.attr('data-original-class') || (typeof options.balloonClass == 'function' ? options.balloonClass.call(self.element[0]) : options.balloonClass);
            balloonClass = (balloonClass || '').toString().replace(/(^\s*|\s*$)/, "");
            return balloonClass;
        },

        addBacking: function () {
            var self = this;
            self.backing = $(backingTemplate);
            var css = {
                width: '99%',
                height: ($(document).height()) + 'px',
                position: 'absolute',
                top: 0,
                left: 'auto',
                right: '0',
                'z-index': 5000
            };
            self.backing.css(css);
            $(document.body).append(self.backing);
            self.backing.on('click.balloon', function (e) {
                self.popBalloon();
            });

        },

        popBalloon: function () {
            var self = this;
            if (self.balloon) {
                self.balloon.remove();
                self.backing.remove();
                $(document).off('.balloon');
                if (self.options.onClose) {
                    self.options.onClose();
                    self.options.onClose = null;
                }
            }
        },


        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);