﻿/*
* Click popovers plugin
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*   bootstrap-tooltip.js
*   bootstrap-popover.js
*/

/* eslint no-unused-vars: 0 */
(function ($) {

    $.widget("nl.timelyPopovers", {
        options: {
            unused: 0
        },

        _create: function () {
            var self = this,
            options = self.options;

            $(document).on("click", ".pop", function (e) {

                var link = $(this);
                $(document).balloon('show', link, {});

            });

        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);