﻿/*
* Choosable plugin
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

/* eslint no-unused-vars: 0 */
(function ($) {
    var iconHtml = '<i class="choose-icon"></i>';

    $.widget("ti.choosable", {
        options: {
            activeClass: 'active',
            actAsRadio: false,
            untickedIcon: '',
            tickedIcon: 'fa fa-check fa-fw',
            relatedToDisable: '.controls',
            relatedContainer: '.control-group',
            choosableClass: 'choosable',
            hideWhenNotChoosable: false
        },

        _create: function () {
            var self = this,
            options = self.options;

            self.choosable = self.element;
            var offText = self.choosable.attr('data-off-text');
            var onText = self.choosable.attr('data-on-text');
            var textHolder = $('span', self.choosable);

            if (options.actAsRadio) {

                self.relatedContainer = self.choosable.closest(options.relatedContainer);
                self.inputContainer = $(options.relatedToDisable, self.relatedContainer);
                self.relatedInputs = $('input, select', self.inputContainer);

                var icon = $(iconHtml);

                if (!self.choosable.hasClass(options.activeClass)) {
                    icon.addClass(options.untickedIcon);
                    self.relatedInputs.prop("readonly", true);
                    if (textHolder.length > 0) {
                        textHolder.html(offText);
                    }
                }
                else {
                    icon.addClass(options.tickedIcon);
                    self.relatedInputs.prop("readonly", false);
                    if (textHolder.length > 0) {
                        textHolder.html(onText);
                    }
                }

                self.choosable.append(icon);

                self.choosable.click(function (e) {

                    e.preventDefault();

                    if (!self.choosable.hasClass(options.choosableClass) && options.hideWhenNotChoosable)
                        return;

                    if (self.choosable.hasClass('read-only'))
                        return;

                    var radio = $('input', self.choosable);
                    var sIcon = $('i.choose-icon', self.choosable);


                    if (!self.choosable.hasClass(options.activeClass)) {
                        self.choosable.addClass(options.activeClass).addClass('btn-success');
                        sIcon.removeClass(options.untickedIcon).show().addClass(options.tickedIcon);
                        if (textHolder.length > 0) {
                            textHolder.html(onText);
                        }
                        radio.prop("checked", true).trigger('change');
                        self.relatedInputs.prop("readonly", false);
                    } else {
                        self.choosable.removeClass(options.activeClass).removeClass('btn-success');
                        sIcon.removeClass(options.tickedIcon).addClass(options.untickedIcon);
                        if (textHolder.length > 0) {
                            textHolder.html(offText);
                        }
                        radio.prop("checked", false).trigger('change');
                        self.relatedInputs.prop("readonly", true);
                    }

                });


            } else {

                self.choosable.click(function (e) {

                    var checkbox = $('input', self.choosable);

                    if (self.choosable.hasClass("active")) {
                        checkbox.prop('checked', false).trigger('change');
                        self.choosable.blur();
                    }
                    else {
                        checkbox.prop('checked', true).trigger('change');
                    }
                });

            }

        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);
