(function () {

    var category = 'automated_msg';
    
    window.timelyEventHub.sub('automated_msg.cancelled', function () {        
        window.timelyGA.track({ category: category, action: 'cancelled', label: 'cancelled_from_add_edit_msg' });
    });

    window.timelyEventHub.sub('automated_msg.saved', function (data) {        
        window.timelyGA.track({ category: category, action: 'saved', label: data.label});
    });

    window.timelyEventHub.sub('automated_sms.deleted', function (data) {
        window.timelyGA.track({ category: category, action: 'deleted', label: 'setup_page_' + data.msg_type + '_' + data.msg_format});
    });
}());
