(function () {

    var category = 'register';
   
    window.timelyEventHub.sub('register.submit', function (data) {
        window.timelyGA.track({ category: category, action: 'submit', label: data.businessName });
    });


    window.timelyEventHub.sub('register.select_size', function (size) {
        window.timelyGA.track({ category: category, action: 'select_size', label: size.id });
    });

}());