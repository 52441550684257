(function () {

    var category = 'account';
    var label = 'account';

   
    window.timelyEventHub.sub('account.activated', function () {
        window.timelyGA.track({ category: category, action: 'account_activated', label: label });
    });


    window.timelyEventHub.sub('account.upgraded', function () {
        window.timelyGA.track({ category: category, action: 'upgrade_account', label: label });
    });


    window.timelyEventHub.sub('account.downgraded', function () {
        window.timelyGA.track({ category: category, action: 'downgrade_account', label: label });
    });


    window.timelyEventHub.sub('account.fast_user_switch', function () {
        window.timelyGA.track({ category: category, action: 'fast_user_switch', label: label });
    });


    window.timelyEventHub.sub('account.fast_user_switch_submit', function () {
        window.timelyGA.track({ category: category, action: 'fast_user_switch_submit', label: label });
    });


    window.timelyEventHub.sub('account.submit_forgot_password', function () {
        window.timelyGA.track({ category: category, action: 'submit_forgot_password', label: label });
    });


    window.timelyEventHub.sub('account.submit_reset_password', function () {
        window.timelyGA.track({ category: category, action: 'submit_reset_password', label: label });
    });


    window.timelyEventHub.sub('account.submit_login', function () {
        window.timelyGA.track({ category: category, action: 'submit_login', label: label });
    });



    /*
     * The following are events from the top bar when trialing and the two react components
     * - Callback panel
     * - Welcome modal
    */
    window.timelyEventHub.sub('account.click_activate_now', function () {
        window.timelyGA.track({ category: category, action: 'click_activate_account_button', label: label });
    });

    window.timelyEventHub.sub('account.click_talk_to_us', function () {
        window.timelyGA.track({ category: category, action: 'talk_to_us', label: label });
    });

    window.timelyEventHub.sub('account.talk_to_us_submit', function () {
        window.timelyGA.track({ category: category, action: 'talk_to_us_submit', label: label });
    });

    window.timelyEventHub.sub('account.welcome_modal_dismiss', function () {
        window.timelyGA.track({ category: category, action: 'welcome_modal_dismiss', label: label });
    });

    window.timelyEventHub.sub('account.welcome_modal_talk_to_us', function () {
        window.timelyGA.track({ category: category, action: 'welcome_modal_talk_to_us', label: label });
    });


}());
