﻿//Shortcuts for adding parsely validation to a form

(function ($) {

    var defaults = {
        form: "",
        validationSummary : ".validation-summary",
        internalSubmitters: "input[type='submit'], button[type='submit']",
        externalSubmitters : "",
        isHorizontalLayout : false
    };

    //Used to show an element using the standard slide animation
    window.timelyParsleyValidate = function (options) {
        
        options = $.extend({}, defaults, options);

        var errorContainerSelector = options.isHorizontalLayout ? ".col-sm-9" : ".form-group";

        var form = $(options.form);

        var submittingButtons = form.find(options.internalSubmitters).add(options.externalSubmitters).submitButton({ form: form });

        var parsleyForm = form.parsley({
            successClass: "success",
            errorClass: "error",
            classHandler: function (el) {
                var handler = el.$element.closest(".form-group");
                return handler;
            },
            errorsContainer: function (el) {
                return el.$element.closest(errorContainerSelector);
            },
            errorsWrapper: "<span class='help-block validation-error'></span>",
            errorTemplate: "<span></span>"
        });

        var checking = false;
        function check() {
            if (!checking) {
                checking = true;
                setTimeout(function () {
                    if (true === parsleyForm.isValid()) {
                        window.timelyFadeOut($('.validation-summary'));
                    }
                    checking = false;
                }, 500);
            }
        }

        function onFormValidate(e) {
            if (!e.validationResult) {
                submittingButtons.submitButton("reset");
                window.timelyShow($(options.validationSummary));
            }
        }

        $.listen("parsley:form:validated", onFormValidate);
        $.listen("parsley:field:validate", check);
    }

})(window.jQuery);