(function () {

    var category = 'sales_new';
    var openTime = 0;
    var afterCompleteActions = 0;



    //
    //  Enable and disable
    //
    window.timelyEventHub.sub('sales_new.enable', function () {
        window.timelyGA.track({ category: category, action: 'enable', label: category });
        $(document.body).addClass('new-sales-enabled');
    });

    window.timelyEventHub.sub('sales_new.disable', function () {
        var salesCompleted = 0; // fetch how many sales this user completed before disabling it
        window.timelyGA.track({ category: category, action: 'disable', label: category, value: salesCompleted });
        $(document.body).removeClass('new-sales-enabled');
    });


    //
    //  New sales main events
    //
    window.timelyEventHub.sub('sales_new.open', function () {
        openTime = new Date().getTime();
        afterCompleteActions = 0;
        window.timelyGA.track({ category: category, action: 'open', label: category });
    });

    window.timelyEventHub.sub('sales_new.close', function (exitStrategy) {
        var closeTime = new Date().getTime();
        var duration = Math.floor((closeTime - openTime) / 1000);
        window.timelyGA.track({ category: category, action: 'close', label: exitStrategy, value: duration });

        if(afterCompleteActions === 0 && exitStrategy === 'done'){
            console.log('sale.track_none_taken');
            window.timelyGA.track({ category: category, action: 'after_complete_action', label: 'none_taken' });
        }
    });

    window.timelyEventHub.sub('sales_new.complete', function () {
        var closeTime = new Date().getTime();
        var duration = Math.floor((closeTime - openTime) / 1000);
        window.timelyGA.track({ category: category, action: 'complete', label: 'complete', value: duration });
    });

    //
    //  New sales UI tracking
    //
    window.timelyEventHub.sub('sales_new.remove_service', function (data) {
        window.timelyGA.track({ category: category, action: 'remove_service', label: data });
    });

    window.timelyEventHub.sub('sales_new.tab_click', function (data) {
        window.timelyGA.track({ category: category, action: 'tab_click', label: data.tab });
    });

    window.timelyEventHub.sub('sales_new.mode_change', function (mode) {
        window.timelyGA.track({ category: category, action: 'mode_change', label: mode });
    });

    window.timelyEventHub.sub('sales_new.after_complete_action', function (action) {
        afterCompleteActions ++;
        window.timelyGA.track({ category: category, action: 'after_complete_action', label: action });
    });

    window.timelyEventHub.sub('sales_new.edit_package_detail', function (detail) {
        window.timelyGA.track({ category: category, action: 'edit_package_detail', label: detail });
    });

    window.timelyEventHub.sub('sales_new.load_saved', function () {
        window.timelyGA.track({ category: category, action: 'load_saved', label: 'load_saved' });
    });

    window.timelyEventHub.sub('sales_new.barcode_scan', function () {
        window.timelyGA.track({ category: category, action: 'barcode_scan', label: 'barcode_scan' });
    });

    window.timelyEventHub.sub('sales_new.payment_failed_error', function (data) {
        window.timelyGA.track({ category: category, action: 'payment_failed_error', label: data.label });
    });

}());
