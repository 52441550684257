(function () {

    var category = 'cardless_checkout';

    window.timelyEventHub.sub('cardless_checkout.enabled', function () {
        window.timelyGA.track({ category: category, action: 'cardless_checkout_enable', label: 'enable' });
    });

    window.timelyEventHub.sub('cardless_checkout.disabled', function () {
        window.timelyGA.track({ category: category, action: 'cardless_checkout_disable', label: 'disable' });
    });

    window.timelyEventHub.sub('cardless_checkout.payment_submit', function () {
        window.timelyGA.track({ category: category, action: 'cardless_checkout_payment_submit', label: 'payment_submit'});
    });

    window.timelyEventHub.sub('cardless_checkout.payment_failed', function (data) {
        window.timelyGA.track({ category: category, action: 'cardless_checkout_payment_failed', label: data.message });
    });

    window.timelyEventHub.sub('cardless_checkout.payment_page_redirect', function () {
        window.timelyGA.track({ category: category, action: 'cardless_checkout_payment_page_redirect', label: 'payment_page_redirect' });
    });

    window.timelyEventHub.sub('cardless_checkout.payment_page_saved', function () {
        window.timelyGA.track({ category: category, action: 'cardless_checkout_payment_page_saved', label: 'payment_page_saved' });
    });

}());
