﻿//Shortcuts for performing standard timely animations

(function () {

    //Used to show an element using the standard slide animation
    window.timelyShow = function timelyShow(element, callback) {
        element.removeClass('hide').show().addClass('run-slide-animation');
        setTimeout(function () {
            element.removeClass('run-slide-animation');
            if (_.isFunction(callback)) callback(element);
        }, 600);
    }

    //Used to hide an element using the standard
    window.timelyHide = function timelyHide(element, callback) {
        element.addClass('run-slide-down-animation');
        setTimeout(function () {
            element.removeClass('run-slide-down-animation');
            element.addClass('hide');
            if (_.isFunction(callback)) callback(element);
        }, 300);
    }

    //Used to hide an element using the standard
    window.timelyFadeOut = function timelyFadeOut(element, callback) {
        element.addClass('run-fadeout-animation');
        setTimeout(function () {
            element.removeClass('run-fadeout-animation');
            element.addClass('hide');
            if (_.isFunction(callback)) callback(element);
        }, 300);
    }


    window.timelyToggle = function timelyShow(element, show, callback) {
        if(show)
        {
            window.timelyShow(element, callback);
        } 
        else
        {
            window.timelyFadeOut(element, callback);
        }
    }

    window.timelyHideNoShrink = function timelyHide(element, callback) {
        element.addClass('run-slide-down-no-shrink-animation');
        setTimeout(function () {
            element.removeClass('run-slide-down-no-shrink-animation');
            element.addClass('hide');
            if (_.isFunction(callback)) callback(element);
        }, 300);
    }

    window.timelyBounceHide = function timelyBounceHide(element, callback) {
        element.addClass('run-bounce-hide-animation');
        setTimeout(function () {
            element.removeClass('run-bounce-hide-animation');
            element.addClass('hide');
            if (_.isFunction(callback)) callback(element);
        }, 700);
    }


})();