/**
* Timely theme for Highcharts JS
* @author Ryan Baker
*/

Highcharts.theme = {
    colors: ["#00b3f0"],
    chart: {
        backgroundColor: {
            linearGradient: [0, 0, 0, 250],
            stops: [[0, "rgb(255, 255, 255)"], [1, "rgb(235, 235, 235)"]]
        }
    },
    plotOptions: {
        series: {
            shadow: false
        }
    },

    xAxis: {
        minPadding: 0,
        maxPadding: 0,
        tickWidth: 0,
        startOnTick: true,
        endOnTick: true
    },
    yAxis: {
        
        lineWidth: 1,
        lineColor: "#C0C0C0"
    }
};

// Apply the theme
var highchartsOptions = Highcharts.setOptions(Highcharts.theme);
