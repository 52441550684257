﻿/*
* Roster plugin
* Depends: jQuery
*/


(function ($) {

    var iconHtml = '<i class="choose-icon"></i>';
    var options = {
        activeClass: 'active',
        untickedIcon: 'fa fa-times',
        tickedIcon: 'fa fa-check'
    };

    var methods = {
        init: function () {

            return this.each(function () {

                var self = this;
                self.choosable = $(self);
                var icon = $(iconHtml);

                if (!self.choosable.hasClass(options.activeClass)) {
                    icon.addClass(options.untickedIcon);
                }
                else {
                    icon.addClass(options.tickedIcon);
                }

                self.choosable.append(icon);

                self.choosable.click(function (e) {

                    e.preventDefault();
                    var radio = $('input:checkbox', self.choosable);
                    var sIcon = $('i.choose-icon', self.choosable);
                    var isWorking;
                    
                    if (!self.choosable.hasClass(options.activeClass)) {
                        self.choosable.addClass(options.activeClass);
                        sIcon.removeClass(options.untickedIcon).show().addClass(options.tickedIcon);
                        isWorking = true;
                    }
                    else {
                        self.choosable.removeClass(options.activeClass);
                        sIcon.removeClass(options.tickedIcon).addClass(options.untickedIcon);
                        isWorking = false;
                    }

                    radio.prop("checked", isWorking);
             
                    var defaultIsWorking = $(this).attr('data-default-is-working') == "True";
                    var defaultStartTime = $(this).attr('data-default-start-time');
                    var defaultEndTime = $(this).attr('data-default-end-time');

                    var times = $('.cell-edit', self.choosable);
                    if (!isWorking && !defaultIsWorking) {
                        times.hide();
                        self.choosable.removeClass('btn-success').removeClass('btn-warning');
                    }
                    else if (isWorking && defaultIsWorking) {
                        times.show();

                        self.choosable.addClass('btn-success').removeClass('btn-warning');

                        $(this).find('.start-time-label').text(defaultStartTime);
                        $(this).find('.end-time-label').text(defaultEndTime);

                        $(this).find('.start-time').val(defaultStartTime);
                        $(this).find('.end-time').val(defaultEndTime);
                    }
                    else {
                        
                        if (isWorking) {
                            times.show();
                        }
                        else {
                            times.hide();
                        }
                        self.choosable.removeClass('btn-success').addClass('btn-warning');
                    }

                });

            });
        },
       

        destroy: function () {
            return this.each(function () {
                var $this = $(this);
                $(window).unbind('.roster');
                $this.removeData('roster');
            });
        }
    };


    $.fn.roster = function (method) {

        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + method + ' does not exist on jQuery.roster');
        }

    };

})(jQuery);