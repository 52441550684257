/* ===========================================================
 * bootstrap-tooltip.js v2.3.2
 * http://twitter.github.com/bootstrap/javascript.html#tooltips
 * Inspired by the original jQuery.tipsy by Jason Frame
 * ===========================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================== */
/* ===========================================================
 * bootstrap-tooltip.js v2.0.2
 * http://twitter.github.com/bootstrap/javascript.html#tooltips
 * Inspired by the original jQuery.tipsy by Jason Frame
 * ===========================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================== */

/* eslint no-unused-vars: 0 */
!function( $ ) {

  "use strict"

 /* TOOLTIP PUBLIC CLASS DEFINITION
  * =============================== */

  var Tooltip = function ( element, options ) {
    this.init('tooltip', element, options)
  }

  Tooltip.prototype = {

    constructor: Tooltip

  , init: function (type, element, options) {



      var eventIn
        , eventOut

      this.type = type
      this.$element = $(element)
      this.options = this.getOptions(options)
      this.enabled = true

      if (this.options.trigger != 'manual') {
        eventIn  = this.options.trigger == 'hover' ? 'mouseenter' : 'focus'
        eventOut = this.options.trigger == 'hover' ? 'mouseleave' : 'blur'
        this.$element.on(eventIn, this.options.selector, $.proxy(this.enter, this))
        this.$element.on(eventOut, this.options.selector, $.proxy(this.leave, this))
      }

      this.$element.addClass('tip-init')
      this.$element.removeClass('tip')

      this.options.selector ?
        (this._options = $.extend({}, this.options, { trigger: 'manual', selector: '' })) :
        this.fixTitle()
  }


  , getOptions: function ( options ) {
      options = $.extend({}, $.fn[this.type].defaults, options, this.$element.data())

      if (options.delay && typeof options.delay == 'number') {
        options.delay = {
          show: options.delay
        , hide: options.delay
        }
      }

      return options
    }

  , enter: function ( e ) {
      var self = $(e.currentTarget)[this.type](this._options).data(this.type)

      if (!self.options.delay || !self.options.delay.show) {
        self.show()
      } else {
        self.hoverState = 'in'
        setTimeout(function() {
          if (self.hoverState == 'in') {
            self.show()
          }
        }, self.options.delay.show)
      }
    }

  , leave: function ( e ) {
      var self = $(e.currentTarget)[this.type](this._options).data(this.type)

      if (!self.options.delay || !self.options.delay.hide) {
        self.hide()
      } else {
        self.hoverState = 'out'
        setTimeout(function() {
          if (self.hoverState == 'out') {
            self.hide()
          }
        }, self.options.delay.hide)
      }
    }

  , show: function () {
      var $tip, inside, pos, actualWidth, actualHeight, placement, tp

      if (this.hasContent() && this.enabled) {
          $tip = this.tip()
          this.setContent()
          
          if (this.options.animation) {
              $tip.addClass('fade')
          }

          placement = typeof this.options.placement == 'function' ?
              this.options.placement.call(this, $tip[0], this.$element[0]) :
              this.options.placement

          inside = /in/ .test(placement)

          $tip
              .remove()
              .css({ top: 0, left: 0, display: 'block' })
              .appendTo(inside ? this.$element : document.body)

          pos = this.getPosition(inside)

          actualWidth = $tip[0].offsetWidth
          actualHeight = $tip[0].offsetHeight
          var place = inside ? placement.split(' ')[1] : placement;
          
          tp = this.correctPlacement(place, pos, actualWidth, actualHeight);

          $tip
              .css(tp)
              .addClass(tp.placement)
              .addClass('in')
      }

      var ele = this.$element;

      //Make sure if the original element is no longer visible after 500ms to hide the tooltip
      setTimeout(function() {
          if ((!ele || ele.width() == 0) && $tip != null) {
              $tip.remove();
          }
      }, 500);

    }

  , correctPlacement: function (placement, pos, actualWidth, actualHeight) {
      var self = this,
      tp;

      tp = self.setCoords(placement, pos, actualWidth, actualHeight);

      var windowWidth = $(window).width();
      var windowHeight = $(window).height();
      var scrollTop = $(window).scrollTop();
      var docHeight = $(document).height();      
      //check within window bounds
      switch (tp.placement) {
        case 'bottom':
            if (tp.top + actualHeight > windowHeight) {
                tp = self.setCoords('top', pos, actualWidth, actualHeight);
            }
            if (tp.top < 0 || tp.left + actualWidth > windowWidth) {
                tp = self.setCoords('left', pos, actualWidth, actualHeight);
            }
            if (tp.left < 0) {
                tp = self.setCoords('right', pos, actualWidth, actualHeight);
            }
            break;
        case 'top':
            if (tp.top < 0) {
                tp = self.setCoords('bottom', pos, actualWidth, actualHeight);
            }
            if (tp.top + actualHeight > windowHeight || tp.left + actualWidth > windowWidth) {
                tp = self.setCoords('left', pos, actualWidth, actualHeight);
            }
            if (tp.left < 0) {
                tp = self.setCoords('right', pos, actualWidth, actualHeight);

                if (tp.left + actualWidth > windowWidth) {
                  tp = self.setCoords('top', pos, actualWidth, actualHeight);
                }
            }
            
            break;
        case 'left':
            if (tp.left < 0) {
                tp = self.setCoords('right', pos, actualWidth, actualHeight);
            }
            if (tp.left + actualWidth > windowWidth) {
                tp = self.setCoords('top', pos, actualWidth, actualHeight);
            }
            if (tp.top < 0) {
                tp = self.setCoords('bottom', pos, actualWidth, actualHeight);
            }
            break;
        case 'right':
            if (tp.left + actualWidth > windowWidth) {
                tp = self.setCoords('left', pos, actualWidth, actualHeight);
            }
            if (tp.left < 0) {
                tp = self.setCoords('top', pos, actualWidth, actualHeight);
            }
            if (tp.top < 0) {
                tp = self.setCoords('bottom', pos, actualWidth, actualHeight);
            }

            if (tp.top + actualHeight > docHeight) {
                tp = self.setCoords('top', pos, actualWidth, actualHeight);
            }

            if (tp.left < 0) {
                //in a tight spot probably down in the lower left corner
                //so will have to just move the balloon to the right and accept it wont look perfect
                tp.left = 0;
                self.balloon.find('.arrow').css({ left: '25%'});
            }

            break;
      }      
      return tp;
    }

  , setCoords: function (placement, pos, actualWidth, actualHeight) {
      var self = this;
      var tp = {};
      switch (placement) {
          case 'bottom':
              tp = { top: pos.top + pos.height, left: pos.left + pos.width / 2 - actualWidth / 2, placement: 'bottom' };
              break;
          case 'top':
              tp = { top: pos.top - actualHeight, left: pos.left + pos.width / 2 - actualWidth / 2, placement: 'top' };
              break;
          case 'left':
              tp = { top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left - actualWidth, placement: 'left' };
              break;
          case 'right':
              tp = { top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left + pos.width, placement: 'right' };
              break;
      }      
      return tp;
    }

  , setContent: function () {
      var $tip = this.tip()
      $tip.find('.tooltip-inner').html(this.getTitle())
      $tip.removeClass('fade in top bottom left right')
    }

  , hide: function () {
      var that = this
        , $tip = this.tip()

      $tip.removeClass('in')

      function removeWithAnimation() {
        var timeout = setTimeout(function () {
          $tip.off($.support.transition.end).remove()
        }, 500)

        $tip.one($.support.transition.end, function () {
          clearTimeout(timeout)
          $tip.remove()
        })
      }

      $.support.transition && this.$tip.hasClass('fade') ?
        removeWithAnimation() :
        $tip.remove()
    }

  , fixTitle: function () {
      var $e = this.$element
      if ($e.attr('title') || typeof($e.attr('data-original-title')) != 'string') {
        $e.attr('data-original-title', $e.attr('title') || '').removeAttr('title')
      }
    }

  , hasContent: function () {
      return this.getTitle()
    }

  , getPosition: function (inside) {
      return $.extend({}, (inside ? {top: 0, left: 0} : this.$element.offset()), {
        width: this.$element[0].offsetWidth
      , height: this.$element[0].offsetHeight
      })
    }

  , getTitle: function () {
      var title
        , $e = this.$element
        , o = this.options

      title = $e.attr('data-original-title')
        || (typeof o.title == 'function' ? o.title.call($e[0]) :  o.title)

      title = (title || '').toString().replace(/(^\s*|\s*$)/, "")

      return title
    }

  , tip: function () {
      return this.$tip = this.$tip || $(this.options.template)
    }

  , validate: function () {
      if (!this.$element[0].parentNode) {
        this.hide()
        this.$element = null
        this.options = null
      }
    }

  , enable: function () {
      this.enabled = true
    }

  , disable: function () {
      this.enabled = false
    }

  , toggleEnabled: function () {
      this.enabled = !this.enabled
    }

  , toggle: function () {
      this[this.tip().hasClass('in') ? 'hide' : 'show']()
    }

  }


 /* TOOLTIP PLUGIN DEFINITION
  * ========================= */

  $.fn.tooltip = function ( option ) {
    return this.each(function () {
      var $this = $(this)
        , data = $this.data('tooltip')
        , options = typeof option == 'object' && option
      if (!data) $this.data('tooltip', (data = new Tooltip(this, options)))
      if (typeof option == 'string') data[option]()
    })
  }

  $.fn.tooltip.Constructor = Tooltip

  $.fn.tooltip.defaults = {
    animation: true
  , delay: 0
  , selector: false
  , placement: 'top'
  , trigger: 'hover'
  , title: ''
  , template: '<div class="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
  }

}( window.jQuery );
