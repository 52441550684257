(function () {

    var category = 'timely_pay';
   
    window.timelyEventHub.sub('timely_pay.setup_modal_opened', function () {
        window.timelyGA.track({ category: category, action: 'setup_modal_opened', label: 'setup_modal' });
    });

    window.timelyEventHub.sub('timely_pay.deactivate_click', function () {
        window.timelyGA.track({ category: category, action: 'deactivate_click', label: 'deactivate' });
    });

    window.timelyEventHub.sub('timely_pay.setup_stripe_business', function () {
        window.timelyGA.track({ category: category, action: 'setup_stripe_business', label: 'business' });
    });

    window.timelyEventHub.sub('timely_pay.setup_stripe_individual', function () {
        window.timelyGA.track({ category: category, action: 'setup_stripe_individual', label: 'individual' });
    });

    window.timelyEventHub.sub('timely_pay.identity_status', function (data) {
        window.timelyGA.track({ category: category, action: 'identity_status', label: data.identityStatus });
    });

    window.timelyEventHub.sub('timely_pay.activation_complete', function (existingGateway) {
        window.timelyGA.track({ category: category, action: 'activation_complete', label: existingGateway });
    });
}());