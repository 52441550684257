(function () {

    var category = 'pin_switching';
    
    window.timelyEventHub.sub('pin_switching.enabled', function (data) {
        window.timelyGA.track({ category: category, action: 'enable_pin_switching', label: data.label });
    });

    window.timelyEventHub.sub('pin_switching.disabled', function () {
        window.timelyGA.track({ category: category, action: 'disable_pin_switching', label: 'disable'});
    });

    window.timelyEventHub.sub('pin_switching.forgotten_pin', function () {
        window.timelyGA.track({ category: category, action: 'forgotten_pin', label: 'send_pin'});
    });
    
    window.timelyEventHub.sub('pin_switching.login_access', function (data) {
        window.timelyGA.track({ category: category, action: 'login_access', label: data.label});
    });

    window.timelyEventHub.sub('pin_switching.lock_clicked', function () {
        window.timelyGA.track({ category: category, action: 'lock_clicked', label: 'manual_lock'});
    });
}());
