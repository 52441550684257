﻿/*
* Deleter plugin
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

/* eslint no-unused-vars: 0 */
(function ($) {

    $.widget("nl.deleter", {
        options: {
            unused: 0
        },

        _create: function () {
            var self = this,
            options = self.options;

            $(document).on("click", "a.deleter", function (e) {

                e.preventDefault();

                var deleteLink = $(e.target);

                var toBeDeleted = $("." + deleteLink.data().deleteClass);
                var toBeDeletedParent = toBeDeleted.parent();

                var animation = toBeDeleted.data('deleter-animation');
                if (!animation) animation = 'slide';

                var deleteUrl = deleteLink.attr('href');
                var deleteFunction = deleteLink.data('function');
                var deleteParameter = deleteLink.data('parameter');
                var currentUrl = window.location.href.toLowerCase();
                var separator = currentUrl.indexOf("/dashboard/default") > -1 ? "/" : ""; //hack for new dash

                if (animation == 'fade') {
                    toBeDeletedParent.addClass('run-fadeout-animation');
                    setTimeout(function () {
                        toBeDeletedParent.hide();
                    }, 300);
                } else {
                    toBeDeletedParent.addClass('run-slide-down-animation');
                    setTimeout(function () {
                        toBeDeletedParent.hide();
                    }, 600);
                }

                $.ajax({
                    type: 'POST',
                    url: separator + deleteUrl,
                    success: function (data, textStatus, XMLHttpRequest) {
                        if (typeof(deleteFunction) != 'undefined' && typeof(deleteParameter) != 'undefined') {
                            window[deleteFunction](deleteParameter);
                        }
                    },
                    dataType: 'html'
                });

            });
        },

        destroy: function () {
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);
        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);