﻿
(function($) {

    var jQueryDataKey = "TimelySubmitButton";

    var defaults = {
        form: "",
        submittingText : "Saving..."
    };

    //define class constructor
    function TimelySubmitButton(el, options) {
       
        var self = this;

        el = $(el);

        options = $.extend({}, defaults, options);
        options.originalText = options.originalText || el.text();

        el.click(function(e) {
            if (!el.is(":disabled")) {
                e.preventDefault();
                el.text(options.submittingText);
                el.attr("disabled", "disabled");
                $(options.form).submit();
            }
        });

        self.reset = function() {
            el.removeAttr("disabled");
            el.text(options.originalText);
        }
    }

    //make class available as a jquery plugin
    $.fn.submitButton = function (args) {
       
        return $(this).each(function () {
           
            var el = $(this);
            
            var existing = el.data(jQueryDataKey);

            if (!existing) {
                //if an instance of the Button class doesn't exist then create one
                el.data(jQueryDataKey, new TimelySubmitButton(el, args));
            }
            else if (typeof args === "string" && typeof existing[args] == "function"){
                //if an action is provided as the argument to the plugin then invoke that method
                existing[args]();
            }
        });
    };

})(jQuery);
