(function () {

    var category = 'booking_modal';
    var categoryBalloon = 'calendar_balloon';
    var fieldNames = {
        'Booking.BookingConfirmationStatusId': 'booking_status',
        'CalendarRecurrenceModel.CalendarRecurrence.RecurrenceTypeId': 'recurrence_type',
        'CalendarRecurrenceModel.CalendarRecurrence.Interval': 'recurrence_interval',
        'CalendarRecurrenceModel.CalendarRecurrence.Occurrences': 'recurrence_occurrences',
        'CalendarRecurrenceModel.CalendarRecurrence.RepeatTypeId': 'recurrence_repeat_type',
        'AutoExpirePencilledInBooking': 'auto_expires',
        'AutoExpireTimesInHoursId': 'auto_expires_hours',
        'IsStaffRequested': 'requested_staff'
    };


    // Calendar balloon
    window.timelyEventHub.sub('calendar_balloon.click_button', function (buttonText) {
        window.timelyGA.track({ category: categoryBalloon, action: 'click_button', label: buttonText });
    });


    // Appt modal
    window.timelyEventHub.sub('booking_modal.open', function () {
        window.timelyGA.track({ category: category, action: 'open', label: category });
    });

    window.timelyEventHub.sub('booking_modal.focus_input', function (fieldName) {
        window.timelyGA.track({ category: category, action: 'focus_input', label: fieldName });
    });
    
    window.timelyEventHub.sub('booking_modal.click_button', function (buttonText) {
        window.timelyGA.track({ category: category, action: 'click_button', label: buttonText });
    });

    window.timelyEventHub.sub('booking_modal.change_option', function (data) {
        if (fieldNames[data.fieldName] === undefined) {
            window.timelyGA.track({ category: category, action: 'change_option', label: data.fieldName });
            return;
        }

        var fieldName = fieldNames[data.fieldName];
        var fieldValue = data.fieldValue;
        window.timelyGA.track({ category: category, action: 'change_' + fieldName, label: fieldValue });
    });

    window.timelyEventHub.sub('booking_modal.click_tab', function (tabName) {
        window.timelyGA.track({ category: category, action: 'click_tab', label: tabName });
    });

    window.timelyEventHub.sub('booking_modal.save_appt', function() {
        window.timelyGA.track({ category: category, action: 'save_appt', label: 'save_appt' });
    });

    window.timelyEventHub.sub('booking_modal.create_appt', function() {
        window.timelyGA.track({ category: category, action: 'create_appt', label: 'create_appt' });
    });

    window.timelyEventHub.sub('booking_modal.save_other', function() {
        window.timelyGA.track({ category: category, action: 'save_other', label: 'save_other' });
    });

}());