
$.fn.clearSelect = function () {
    return this.empty();
};

$.fn.fillOptionSelect = function (data) {
    return this.clearSelect().each(function () {
        if (this.tagName == 'SELECT') {

            var groupData = _.groupBy(data, function (obj) {
                var groupName = '';
                if (obj.GroupName) {
                    groupName = obj.GroupName;
                }
                return groupName;
            });


            var optGroups = [];
            for (var key in groupData) {
                if (groupData.hasOwnProperty(key)) {
                    var optGroup = $("<optgroup></optgroup>");
                    optGroup.attr("label", key);
                    var currentGroup = groupData[key];
                    for (var i = 0; i < currentGroup.length; i++) {
                        $("<option />").attr("value", currentGroup[i].Value).attr("selected", currentGroup[i].Selected).html(currentGroup[i].Text).appendTo(optGroup);
                    }
                    optGroups.push(optGroup);
                }
            }

            for (var i = 0; i < optGroups.length; i++) {
                $(this).append(optGroups[i]);
            }
        }
    });
};

$.fn.prependOptionSelect = function (data) {
    return this.each(function () {
        if (this.tagName == 'SELECT') {

            var groupData = _.groupBy(data, function (obj) {
                var groupName = '';
                if (obj.GroupName) {
                    groupName = obj.GroupName;
                }
                return groupName;
            });


            var optGroups = [];
            for (var key in groupData) {
                if (groupData.hasOwnProperty(key)) {
                    var optGroup = $("<optgroup></optgroup>");
                    optGroup.attr("label", key);
                    var currentGroup = groupData[key];
                    for (var i = 0; i < currentGroup.length; i++) {
                        $("<option />").attr("value", currentGroup[i].Value).attr("selected", currentGroup[i].Selected).html(currentGroup[i].Text).appendTo(optGroup);
                    }
                    optGroups.push(optGroup);
                }
            }

            for (var i = optGroups.length - 1; i >= 0; i--) {
                $(this).prepend(optGroups[i]);
            }
        }
    });
};


$.fn.fillSelect = function (data) {
    return this.clearSelect().each(function () {
        if (this.tagName == 'SELECT') {
            var dropdownList = this;
            $.each(data, function (index, optionData) {
                var option = new Option(optionData.Text, optionData.Value, optionData.Selected, optionData.Selected);
                if ($.timelyBrowser.msie) {
                    dropdownList.add(option);
                }
                else {
                    dropdownList.add(option, null);
                }
            });
        }
    });
};

Number.prototype.formatMoney = function (c, d, t) {
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

$(document).on('click', '.t-slider__label', function(e) {
    $(this).toggleClass('on');
    $(this).find('input[type=checkbox]').prop('checked', $(this).hasClass("on"));
   // $(this).find('input[type=checkbox]').click();
    e.preventDefault();
});


