﻿(function () {

    var category = 'credit_card_detection';

    window.timelyEventHub.sub('credit_card_detection.false_positive', function (data) {
        window.timelyGA.track({ category: category, action: 'false_positive', label: data.location });
    });

    window.timelyEventHub.sub('credit_card_detection.submit_with_detection', function (data) {
        window.timelyGA.track({ category: category, action: 'submit_with_detection', label: data.location });
    });

    window.timelyEventHub.sub('credit_card_detection.merchant_form', function (data) {
        window.timelyGA.track({ category: category, action: 'merchant_form', label: data.location });
    });

    window.timelyEventHub.sub('credit_card_detection.timely_pay_setup', function (data) {
        window.timelyGA.track({ category: category, action: 'timely_pay_setup', label: data.location });
    });

    window.timelyEventHub.sub('credit_card_detection.learn_more', function (data) {
        window.timelyGA.track({ category: category, action: 'learn_more', label: data.location });
    });

    window.timelyEventHub.sub('credit_card_detection.question_saved_asking_for_card', function () {
        window.timelyGA.track({ category: category, action: 'question_saved_asking_for_card', label: 'service_setup' });
    });

    window.timelyEventHub.sub('credit_card_detection.learn_more_about_timelypay', function () {
        window.timelyGA.track({ category: category, action: 'learn_more_about_timelypay', label: 'service_setup' });
    });
}());
