(function () {

    var category = 'rewards';

    window.timelyEventHub.sub('rewards.enabled', function () {
        window.timelyGA.track({ category: category, action: 'enabled', label: 'settings' });
    });

    window.timelyEventHub.sub('rewards.disabled', function () {
        window.timelyGA.track({ category: category, action: 'disabled', label: 'settings' });
    });

    window.timelyEventHub.sub('rewards.set_formula', function (data) {
        window.timelyGA.track({ category: category, action: 'set_formula_points', label: 'settings', value: data.points });
        window.timelyGA.track({ category: category, action: 'set_formula_voucher', label: 'settings', value: data.voucherValue });
    });

    window.timelyEventHub.sub('rewards.set_formula_custom', function () {
        window.timelyGA.track({ category: category, action: 'set_formula_custom', label: 'settings' });
    });

    window.timelyEventHub.sub('rewards.set_formula_preset', function () {
        window.timelyGA.track({ category: category, action: 'set_formula_preset', label: 'settings' });
    });

    window.timelyEventHub.sub('rewards.change_formula', function () {
        window.timelyGA.track({ category: category, action: 'change_formula', label: 'settings' });
    });

    window.timelyEventHub.sub('rewards.average_customer_spend', function (data) {
        window.timelyGA.track({ category: category, action: 'average_customer_spend', label: 'settings', value: data.averageSpend });
    });

    window.timelyEventHub.sub('rewards.product_setting', function (data) {
        window.timelyGA.track({ category: category, action: 'product_setting', label: 'settings', value: data.productSetting });
    });

    window.timelyEventHub.sub('rewards.service_setting', function (data) {
        window.timelyGA.track({ category: category, action: 'service_setting', label: 'settings', value: data.serviceSetting });
    });

    window.timelyEventHub.sub('rewards.expiry_enabled', function () {
        window.timelyGA.track({ category: category, action: 'expiry_enabled', label: 'settings'});
    });

    window.timelyEventHub.sub('rewards.expiry_disabled', function () {
        window.timelyGA.track({ category: category, action: 'expiry_disabled', label: 'settings'});
    });

    window.timelyEventHub.sub('rewards.expiry_timeframe', function (data) {
        window.timelyGA.track({ category: category, action: 'expiry_timeframe', label: 'settings', value: data.expiryLengthDays });
    });

    window.timelyEventHub.sub('rewards.expiry_backfill', function () {
        window.timelyGA.track({ category: category, action: 'expiry_backfill', label: 'settings'});
    });

    window.timelyEventHub.sub('rewards.more_info', function () {
        window.timelyGA.track({ category: category, action: 'more_info', label: 'settings' });
    });

    window.timelyEventHub.sub('rewards.upsell_more_info', function () {
        window.timelyGA.track({ category: category, action: 'upsell_more_info', label: 'settings' });
    });

    window.timelyEventHub.sub('rewards.upsell_upgrade', function () {
        window.timelyGA.track({ category: category, action: 'upsell_upgrade', label: 'settings' });
    });

    window.timelyEventHub.sub('rewards.voucher_earned', function (value) {
        window.timelyGA.track({ category: category, action: 'voucher_earned', label: 'collection', value: value });
    });

    window.timelyEventHub.sub('rewards.voucher_redeemed', function (value) {
        window.timelyGA.track({ category: category, action: 'voucher_redeemed', label: 'redemption', value: value });
    });

    window.timelyEventHub.sub('rewards.open_edit_points', function () {
        window.timelyGA.track({ category: category, action: 'open_edit_points', label: 'adjust_points' });
    });

    window.timelyEventHub.sub('rewards.points_increase', function(data) {
        window.timelyGA.track({ category: category, action: 'points_increase', label: 'adjust_points', value: data.points });
    });

    window.timelyEventHub.sub('rewards.points_decrease', function(data) {
        window.timelyGA.track({ category: category, action: 'points_decrease', label: 'adjust_points', value: data.points });
    });

    window.timelyEventHub.sub('rewards.cancel_edit_points', function () {
        window.timelyGA.track({ category: category, action: 'cancel_edit_points', label: 'adjust_points' });
    });

}());
