(function () {

    var category = 'sales';
   
    window.timelyEventHub.sub('sales.issue_refund', function () {
        window.timelyGA.track({ category: category, action: 'create refund', label: 'issue refund' });
    });

    window.timelyEventHub.sub('sales.save_refund', function () {
        window.timelyGA.track({ category: category, action: 'save refund', label: 'save refund' });
    });

    window.timelyEventHub.sub('sales.refund_payment', function () {
        window.timelyGA.track({ category: category, action: 'pay refund', label: 'refund payment' });
    });

    window.timelyEventHub.sub('sales.delete_invoice', function () {
        window.timelyGA.track({ category: category, action: 'delete_invoice', label: 'delete' });
    });

    window.timelyEventHub.sub('sales.edit_invoice', function () {
        window.timelyGA.track({ category: category, action: 'edit_invoice', label: 'edit' });
    });

    window.timelyEventHub.sub('sales.apply_payment', function () {
        window.timelyGA.track({ category: category, action: 'apply_payment_to_invoice', label: 'edit' });
    });

}());
