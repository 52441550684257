(function () {

    var category = 'sms_campaigns';

    window.timelyEventHub.sub('sms_campaigns.create_campaign', function () {
        window.timelyGA.track({ category: category, action: 'create', label: 'campaign' });
    });

    window.timelyEventHub.sub('sms_campaigns.send_campaign', function () {
        window.timelyGA.track({ category: category, action: 'send', label: 'campaign' });
    });

    window.timelyEventHub.sub('sms_campaigns.message_parts_count', function (parts) {
        window.timelyGA.track({ category: category, action: 'message_parts_count', label: parts.toString() });
    });

    window.timelyEventHub.sub('sms_campaigns.send_campaign_success', function () {
        window.timelyGA.track({ category: category, action: 'send_success', label: 'campaign' });
    });

    window.timelyEventHub.sub('sms_campaigns.send_campaign_failure', function () {
        window.timelyGA.track({ category: category, action: 'send_failure', label: 'campaign' });
    });

    window.timelyEventHub.sub('sms_campaigns.send_campaign_failure_error_code', function (code) {
        window.timelyGA.track({ category: category, action: 'send_failure_error_code', label: code.toString() });
    });

    window.timelyEventHub.sub('sms_campaigns.auto_apply_filter', function () {
        window.timelyGA.track({ category: category, action: 'auto_apply_filter', label: 'filter' });
    });

    window.timelyEventHub.sub('sms_campaigns.manual_apply_filter', function () {
        window.timelyGA.track({ category: category, action: 'manual_apply_filter', label: 'filter' });
    });

    window.timelyEventHub.sub('sms_campaigns.apply_filter_success', function () {
        window.timelyGA.track({ category: category, action: 'apply_filter_success', label: 'filter' });
    });

    window.timelyEventHub.sub('sms_campaigns.apply_filter_failure', function () {
        window.timelyGA.track({ category: category, action: 'apply_filter_failure', label: 'filter' });
    });

    window.timelyEventHub.sub('sms_campaigns.cancel_campaign', function (path) {
        var pageName = /[^/]*$/.exec(path)[0] + '_page';
        window.timelyGA.track({ category: category, action: 'cancel', label: pageName });
    });

    window.timelyEventHub.sub('sms_campaigns.navigate_back', function (path) {
        var pageName = /[^/]*$/.exec(path)[0] + '_page';
        window.timelyGA.track({ category: category, action: 'back', label: pageName });
    });

}());
