(function () {

    var openTime = 0;

    var category = 'sales_modal';
    var fieldNames = {
        'MarkAsCompleted': {
            name: 'mark_as_completed'
        },
        'Invoice_LocationId': {
            name: 'location'
        },
        'Invoice_InvoiceDate': {
            name: 'invoice_date'
        },
        'Invoice_DueDate': {
            name: 'invoice_due_date'
        }
    };

    var getFieldVal = function (fieldName) {
        var fieldVal = false;
        var fieldEl = $('.invoice #' + fieldName);

        if (fieldEl.attr('type') === 'checkbox') {
            if (fieldEl.attr('checked') === 'checked') {
                fieldVal = true;
            }
        } else {
            fieldVal = fieldEl.val();
        }

        return fieldVal;
    };


    //
    //  Listeners
    //
    window.timelyEventHub.sub('sales_modal.open', function () {
        window.timelyGA.track({ category: category, action: 'open', label: category });
        openTime = new Date().getTime();

        for (var fieldName in fieldNames) {
            var fieldVal = getFieldVal(fieldName);
            fieldNames[fieldName]['default'] = fieldVal;
            fieldNames[fieldName]['changed'] = 0;
        }
    });

    window.timelyEventHub.sub('sales_modal.done', function (isSave) {
        for (var fieldName in fieldNames) {
            (function () {
                var field = fieldNames[fieldName];
                var name = field.name;
                var newValue = getFieldVal(fieldName);
                var defaultValue = field.default;
                var changedCount = field.changed;
                var eventLabel = newValue !== defaultValue ? 'changed_from_default' : 'changed';

                if (changedCount > 0) {
                    window.timelyGA.track({ category: category, action: 'option_' + name + '_changed', label: eventLabel, value: changedCount });
                }
            }());
        }

        if (isSave && openTime !== 0) {
            var closeTime = new Date().getTime();
            var duration = Math.floor((closeTime - openTime) / 1000);
            window.timelyGA.track({ category: category, action: 'save', label: category, value: duration });
            openTime = 0;
        } else {
            window.timelyGA.track({ category: category, action: 'close', label: category });
        }
    });

    // When the sale is completed / received payment
    window.timelyEventHub.sub('sales_modal.complete', function () {
        var closeTime = new Date().getTime();
        var duration = Math.floor((closeTime - openTime) / 1000);
        window.timelyGA.track({ category: category, action: 'complete', label: 'complete', value: duration });
    });

    window.timelyEventHub.sub('sales_modal.focus_input', function (fieldName) {
        window.timelyGA.track({ category: category, action: 'focus_input', label: fieldName });
    });

    window.timelyEventHub.sub('sales_modal.click_button', function (buttonText) {
        window.timelyGA.track({ category: category, action: 'click_button', label: buttonText });
    });

    window.timelyEventHub.sub('sales_modal.change_option', function (data) {
        if (fieldNames[data.fieldName] === undefined) {
            window.timelyGA.track({ category: category, action: 'change_option', label: data.fieldName });
            return;
        }

        var field = fieldNames[data.fieldName];
        var fieldName = field.name;
        var fieldValue = data.fieldValue;

        field.changed ++;
        window.timelyGA.track({ category: category, action: 'change_' + fieldName, label: fieldValue });
    });

}());
